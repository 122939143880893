// src/Text.js

import React from 'react'
import * as R from 'react-native';
import * as Constants from '../theme';


function Text(props) {
    let defaultStyle = Constants.styles.defaultText;

    return <R.Text {...props} style={[defaultStyle, props.style]}>{props.children}</R.Text>
}

export default Text
// src/BasicInformation.js

import React, { useEffect, useState, useRef } from 'react'
import { View, StyleSheet, TextInput, ActivityIndicator, useWindowDimensions, Dimensions } from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import TextInputField, { SelectField, RadioField } from './Input';
import * as Data from './libs/data'
import ToggleField from './ToggleField';
import { Icon } from 'native-base'
import { AntDesign, FontAwesome, Ionicons } from '@expo/vector-icons';
import Text from './Text';

function BasicInformation(props) {

    let [showNicotineOptions, setShowNicotineOptions] = useState(false);
    let [optionsChanged, setOptionsChanged] = useState(false);
    let [getAge, setAge] = useState(0);
    let [getAgeFromDOB, setAgeFromDOB] = useState(0);


    let updateNicotineState = props.getNicotineUsage;
    let [previousNicotineChoice, setPreviousNicotineChoice] = useState('');
    const [ageOrDOB, setAgeOrDOB] = useState(1);

    let caseLoadCount = useRef(0);


    /* age stuff */
    let [displayed, setDisplayed] = useState(1);
    let [reprocessAge, setReprocessAge] = useState(false);

    useEffect(() => {
        caseLoadCount.current += props.resetCount;
    }, [props.resetCount]);


    useEffect(() => {
        if (!props.bppCase) return;
        if (props.bppCase == 'reset') return;

        const params = Data.getParams();

        try {
            setDisplayed(1);
            setAgeOrDOB(1);
            if (props.bppCase.autosaveRestore && !params.skip_recall) {
                props.setSaveLoadLastAction("Recovered Last Case");
            } else {
                if (props.caseName != '') {
                    props.setSaveLoadLastAction(`Last Loaded Case: ${Data.truncateCaseDisplay(props.caseName)}`);
                } else {
                    props.setSaveLoadLastAction(``);
                }
            }
            if (props.bppCase.dob)
                props.setDOB(props.bppCase.dob);
            if (props.bppCase.height && props.bppCase.height > 0)
                props.setHeight(`${Math.floor(props.bppCase.height / 12)}'${("" + (props.bppCase.height % 12)).length == 1 ? '0' : ''}${props.bppCase.height % 12}"`);
            if (props.bppCase.weight && props.bppCase.weight > 0)
                props.setWeight(props.bppCase.weight);
            if (props.bppCase.sex)
                props.setSex(props.bppCase.sex);

            props.setNicotineFrequency(props.bppCase.nicotine_months.length > 0 ? props.bppCase.nicotine_months : '99999');
            if (props.bppCase.hasOwnProperty('NicotineOptions')) {
                if (props.bppCase.NicotineOptions.hasOwnProperty('NicotineOption')) {

                    let x = {};
                    let any = false;
                    if (Array.isArray(props.bppCase.NicotineOptions.NicotineOption)) {
                        props.bppCase.NicotineOptions.NicotineOption.forEach(function (obj) {
                            x[obj.Name] = obj.Frequency;
                            if (obj.Frequency != 'Never') {
                                any = true;
                            }
                        });
                    } else {
                        x[props.bppCase.NicotineOptions.NicotineOption.Name] = props.bppCase.NicotineOptions.NicotineOption.Frequency;
                    }

                    props.setNicotineUsageAnswers(x);


                    props.nicotine_options.forEach(function (id) {
                        if (x.hasOwnProperty(id)) {
                            updateNicotineState[id] = x[id];
                            if (x[id] != 'Never') {
                                any = true;
                            }
                            setReprocessAge(true);
                            props.setNicotineFrequency(Constants.NicotineTimings[1].value);
                            setOptionsChanged(true);
                        } else {
                            updateNicotineState[id] = 'Never';
                        }
                    });
                    props.setNicotineUsage(updateNicotineState);
                    setShowNicotineOptions(any);
                } else {
                    setShowNicotineOptions(false);
                }
            }
        } catch (e) {
            console.log(e);
        }
        props.setBasicInfoLoadedRef(true);
        caseLoadCount.current++;
    }, [props.bppCase])

    useEffect(() => {
        if (ageOrDOB == 2) return;
        if (isNaN(Date.parse(props.getDOB)) ||
            (new Date(Date.parse(props.getDOB)) < new Date(Date.parse('01-01-1900'))) ||
            (new Date(Date.parse(props.getDOB)) > new Date())) {

            setAge('');
            return;
        }
        setAge(Data.dobToAge(props.getDOB));
        setAgeFromDOB(Data.dobToAge(props.getDOB));
        setReprocessAge(false);
    }, [props.getDOB, ageOrDOB, reprocessAge]);

    useEffect(() => {
        props.setDOB('');
        setAge('');
        setAgeFromDOB('');
    }, [ageOrDOB])

    useEffect(() => {
        if (getAgeFromDOB == '' || ageOrDOB == 1) return;


        // if we already have a dob
        /*if (isNaN(Date.parse(getAgeFromDOB)) ||
            (new Date(Date.parse(getAgeFromDOB)) < new Date(Date.parse('01-01-1900'))) ||
            (new Date(Date.parse(getAgeFromDOB)) > new Date())) {
            setAge('');
            return;
        }*/

        var age_int = parseInt(getAgeFromDOB);
        if (age_int < 0) {
            age_int = 0;
        }
        if (age_int > 122) {
            age_int = 122;
        }


        let dateOfBirth = new Date();
        dateOfBirth.setMonth(dateOfBirth.getMonth() - (age_int * 12));

        props.setDOB(Data.get_formatted_date(dateOfBirth.toDateInputValue()));

    }, [getAgeFromDOB]);

    const DisplayNicotineOptions = (args) => {
        const options = args.options;
        const show = args.show;
        const nicotineStates = args.nicotineStates;

        if (!options || options.length == 0) return (
            <View style={show ? {} : { display: 'none' }}>
                <Row label='' style={{ marginTop: -.5 * Constants.leftOffset }}>
                    <ActivityIndicator style={{ alignItems: 'flex-start' }} animating={options.length == 0} size="large" color={Constants.colors.primaryColorBG} />
                </Row>
            </View >
        );

        options.forEach(function (id) {
            if (nicotineStates.hasOwnProperty(id)) {
                updateNicotineState[id] = nicotineStates[id];
            } else {
                updateNicotineState[id] = 'Never';
            }
        });
        props.setNicotineUsage(updateNicotineState)

        return (
            <View style={show ? {} : { display: 'none' }}>
                {options.map((id) => {
                    return <Row label={id}>
                        <SelectField key={`nicotine-${caseLoadCount}-${id}`} id={`nicotine - ${id} - usage`} selectStyle={styles.select}
                            defaultValue={updateNicotineState[id]}
                            value={updateNicotineState[id]}
                            options={Constants.NicotineOptions}
                            readonly={props.isReadonly.current}

                            onValueChange={(itemValue) => {
                                updateNicotineState[id] = (itemValue == '99999' ? 'Never' : itemValue);
                                props.setNicotineUsage(updateNicotineState);
                                let currentAnswers = {};
                                Object.keys(updateNicotineState).forEach(function (id) {
                                    currentAnswers[updateNicotineState[id].toLowerCase()] = updateNicotineState[id].toLowerCase();
                                });
                                if (Object.keys(currentAnswers).length == 1 && currentAnswers['never'] != undefined) {
                                    /* only 1 option and it's "never", if we're within the past 12 months, correct that to be never so the user can change it */
                                    if (props.getNicotineFrequency == Constants.NicotineTimings[1].value) {
                                        props.setNicotineFrequency(previousNicotineChoice);
                                        setPreviousNicotineChoice('');
                                    }
                                    setOptionsChanged(false);
                                } else {
                                    /* at least 1 non-never answer for the past 12 months*/

                                    if (previousNicotineChoice == '') {
                                        setPreviousNicotineChoice(props.getNicotineFrequency);
                                    }
                                    props.setNicotineFrequency(Constants.NicotineTimings[1].value);
                                    setOptionsChanged(true);

                                }
                                props.setNicotineUsageAnswers(updateNicotineState);
                            }}></SelectField>
                    </Row>
                })
                }

            </View >
        );
    };

    let [eraseBothFields, setEraseBothFields] = useState(false);

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    return (
        <Pane heading='Basic Client Information' nativeID='basic-information' style={{ zIndex: 0 }} secondaryNoPress={1} secondary={
            <Text style={[Constants.styles.lastLoadedCase, { color: Constants.colors.textColor, }]}>{props.saveLoadLastAction}</Text>
        }>
            <Row key={`row-dob-${caseLoadCount.current}`} label={ageOrDOB == 1 ? 'Date of Birth' : 'Age'} rightStyle={[{ height: 42 }, (getAge || ageOrDOB ? { marginBottom: isMobile ? Constants.leftOffset : 0 } : null)]}>
                <View style={[Constants.styles.flex]}>
                    <ToggleField displayed={displayed} setDisplayed={setDisplayed} readonly={props.isReadonly.current}
                        onToggle={() => { setAgeFromDOB(''); props.setDOB(''); setEraseBothFields(true) }}
                        key={`tf-dob-${caseLoadCount.current}`}
                        setter={setAgeOrDOB}
                        one={
                            <View style={[Constants.styles.flex]}><TextInputField
                                autoFocus={true}
                                onKeyPress={(e) => {
                                    if (!Data.is_registered(props.registrationDetails)) {
                                        props.setShowSoftwareRegistrationModal(true);
                                        return e.preventDefault();
                                    }
                                    if (e.key == '!') {
                                        setAgeFromDOB('');
                                        props.setDOB('');

                                    }
                                }}
                                eraseField={eraseBothFields} setEraseField={setEraseBothFields}
                                style={[Constants.styles.textControl,
                                ]} value={props.getDOB} getter={props.getDOB} setter={props.setDOB}
                                id="dob" type="date" placeholder='MM/DD/YYYY' editable={!props.isReadonly.current}></TextInputField>
                            </View>
                        }
                        switchToOne='Enter date of birth'
                        two={
                            <TextInputField
                                key={`input-age-${caseLoadCount.current}`}
                                onKeyPress={(e) => {
                                    if (!Data.is_registered(props.registrationDetails)) {
                                        props.setShowSoftwareRegistrationModal(true);
                                        return e.preventDefault();
                                    }
                                    if (e.key == '!') {
                                        setAgeFromDOB('');
                                        props.setDOB('');
                                    }
                                }}
                                eraseField={eraseBothFields} setEraseField={setEraseBothFields}
                                style={[Constants.styles.textControl]}
                                getter={getAgeFromDOB} setter={setAgeFromDOB} id="age" type="number" editable={!props.isReadonly.current}></TextInputField>
                        }
                        switchToTwo='Enter age'
                    ></ToggleField>
                    {ageOrDOB == 2 ?
                        <Text style={[Constants.styles.textControlCaption, Constants.styles.flexRow, { marginTop: 2, marginBottom: 30, fontSize: Constants.fontSizes.caption, alignItems: 'center' }]}><Icon as={Ionicons} name='alert-circle' style={{ color: Constants.colors.alert, marginRight: 7.43 }} />Use the date of birth for the most accurate quote.</Text>
                        :
                        (getAge ? <Text style={[Constants.styles.textControlCaption, { marginBottom: 30, marginTop: 5 }]}>The client is {getAge} years old</Text> : null)
                    }
                </View>
            </Row >
            <Row label='Height'>
                <TextInputField key={`height-${caseLoadCount.current}`} onKeyPress={(e) => {
                    if (!Data.is_registered(props.registrationDetails)) {
                        props.setShowSoftwareRegistrationModal(true);
                        return e.preventDefault();
                    }
                }}
                    value={props.height} setter={props.setHeight}
                    style={Constants.styles.textControlSM} id="height" type='number' mask='height' editable={!props.isReadonly.current}></TextInputField>
            </Row>
            <Row label='Weight'>
                <TextInputField key={`weight-${caseLoadCount.current}`} editable={!props.isReadonly.current} onKeyPress={(e) => {
                    if (!Data.is_registered(props.registrationDetails)) {
                        props.setShowSoftwareRegistrationModal(true);
                        return e.preventDefault();
                    }
                }}
                    value={props.weight} setter={props.setWeight} onKeyUp={(e) => { if (e.key == 'f' || e.key == 'F') { !props.isReadonly.current && props.setSex('Female') } if (e.key == 'm' || e.key == 'M') { !props.isReadonly.current && props.setSex('Male') } }}
                    style={Constants.styles.textControlSM} id="weight" type='number' maxlength='3' endAdornment="lbs" adornmentClass={Constants.styles.textControlSMCaption}>
                </TextInputField>
            </Row>
            <Row label='Sex'>
                <View>
                    <RadioField name="sex" id="sex" key={`sex-${caseLoadCount.current}`}
                        getter={props.sex} setter={props.setSex} readonly={props.isReadonly.current}
                        options={[{ label: 'Male', value: 'Male', id: 'sex-m' }, { label: 'Female', value: 'Female', id: 'sex-f' }]}></RadioField>
                </View>
            </Row>
            <Row label='Nicotine Usage'>
                <SelectField isDisabled={false} id='nicotine-selector' readonly={props.isReadonly.current}
                    getter={props.getNicotineFrequency} setter={props.setNicotineFrequency}
                    key={`nicotine-selector-${caseLoadCount.current}`} editable={!props.isReadonly.current}
                    name='nicotine-selector' selectStyle={[styles.select, showNicotineOptions ? null : Constants.styles.disabledSelect]}
                    onValueChange={(val) => {
                        if (val == showNicotineOptions) return;

                        if (showNicotineOptions == true) {
                            props.setNicotineUsageAnswers([]);
                            setShowNicotineOptions(false);
                        } else {
                            if (val == 6) { // within 12 months
                                setShowNicotineOptions(true);
                            }
                        }
                    }}
                    defaultValue={props.getNicotineFrequency} options={Constants.NicotineTimings}></SelectField>
            </Row>
            <Row label='' bottomSpacing={showNicotineOptions == true ? Constants.leftOffset * .5 : Constants.rowMarginBottom}>
                <Text selectable={false} style={[styles.showNicotineOptionsLink, { maxWidth: 300 }]}
                >
                    {showNicotineOptions == true ? 'Updating the optional nicotine types below may impact underwriting and rates.' : ''}
                </Text>
            </Row>

            {DisplayNicotineOptions({
                options: props.nicotine_options,
                show: showNicotineOptions,
                nicotineStates: props.nicotineUsageAnswers
            })}

        </Pane >);
}

export default BasicInformation

const showNicotineOptionsLinkTopSpacing = 14;
const styles = new StyleSheet.create({
    radio: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.input,
    },
    select: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.input,
        width: Constants.controlMaxWidth,
        borderRadius: '0px',
        marginTop: showNicotineOptionsLinkTopSpacing, // should match the marginTop for showNicotineOptionsLink
    },
    showNicotineOptionsLink: {
        fontWeight: '400',
        marginTop: -1 * showNicotineOptionsLinkTopSpacing,
        marginBottom: showNicotineOptionsLinkTopSpacing * -1,
    },
    hidden: {
        display: 'none',
    },
    disabled: {
        cursor: 'not-allowed',
    }
});

/* need to add actual custom css: and need to add distance for the labels and radios... (8px from button to label)
TextInputField[type=radio]
    background-color: // blue
*/

// src/SlideIns.js

import React, { useEffect, useState, useRef } from 'react'
import { View, StyleSheet, Pressable, Modal, Platform, Image, Dimensions, useWindowDimensions } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome, Feather } from '@expo/vector-icons';
import { Box, Icon, IconButton } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField, { CheckField, FilterField, BooleanSwitch } from './Input'
import Link from './Link';
import * as Data from './libs/data'
import KeyboardShortcut from './KeyboardShortcut';
import Text from './Text';
import ResultBadges from './ResultBadges';

var msg_ERR_ACTIVE_ELSEWHERE = "You've reached the maximum allowed activations for this license.  Please deactivate BestPlanPro on another device before activating it here.";
var msg_ERR_INACTIVE = "This license is inactive.  Please contact support for additional help.";
var msg_ERR_LOCKED = "This license is locked.  If you have a multi-activation license, you can unlock this license on the Customer portal.  If this was locked automatically for security reasons, it will unlock automatically within a few hours.  Contact support for more information.";
var msg_ERR_MAX_ACTIVATIONS = "This license has already been activated.  Cannot activate this installation until the license key is deactivated from another installation.  Contact support for additional help.";
var msg_DEACTIVATION_SUCCESSFUL = 'Deactivation was successful.';
var msg_UNKNOWN_ACTIVATION_ERROR = 'Something went wrong activating BestPlanPro.  Please double check your credentials.';
function msg_ACTIVATION_SUCCESSFUL(remaining_activations) {
    var s = "s";
    if (remaining_activations == "1") {
        s = "";
    }
    return "Activation was successful.";
    if (remaining_activations != 0) {
        return "Activation was successful.  You have " + remaining_activations + " remaining activation" + s + " on this license.  You can deactivate this copy to increase the number of activations remaining."
    } else {
        return "Activation was successful.  You have " + remaining_activations + " remaining activation" + s + " on this license.  You can deactivate this copy to increase the number of activations remaining.  If you have cleared your browsing data without deactivating the software first, your activation count may be inaccurate, however the software will still activate and deactivate normally."
    }
}


var msg_WARN_NO_DOSAGE = 'Warning: Remove dosage from name field or results may be inaccurate (use Dosage field instead).';
var msg_START_MED_ENTRY = '[Option 1: Enter the Name of the Medication (autocomplete & typo correction will assist)<br /><br />Option 2: If the medication name is unknown, enter what it is prescribed to treat and medication options will be shown.<br /><br />You can use this box to click on options or you can type in the appropriate field.<br /><br />You can override the software at any time.]';
var msg_RERUN_CASE_PROBLEM = 'Something went wrong.  Please <a href="#/" onclick="save_data(true)">click here to save your case</a> and refresh the page / contact support.  If directed to, you can <a href="#/" onclick="analyze_data()">re-run this case (by clicking here)</a>, but do not re-run this case several times if there continues to be a problem.';

var msg_HIDE_COMPETITIVE_RESULTS = "Only Show Your Checked Products / Carriers";
var msg_SHOW_COMPETITIVE_RESULTS = "Show All FEX Products / Carriers";

const ModalBackdropClickDetect = (props) => {
    return (
        <Pressable style={{ position: 'absolute', width: '100%', height: '100%' }} onPressOut={(e) => { if (props.onPress) props.onPress(e); else props.onPressOut(e); }}> </Pressable>
    )
}

export default function SetAvailableProductsSlideIn(props) {


    if (props.isReadonly.current) return;

    if (props.preventAction.product_selection) return;



    // @TODO add logic for core processing with state derivatives and get_products_str()


    let [checked, setChecked] = useState({});
    let [filters, setFilters] = useState([]);
    let [searchName, setSearchName] = useState('');
    let [filterOptions, setFilterOptions] = useState([]);
    let [forceClose, setForceClose] = useState(false);


    let refreshCount = useRef(0);
    let filterRefresh = useRef(0);

    let isMobile = useWindowDimensions().width < 600;

    if (!props.allProducts) return;

    const getPrettyNameForType = (key) => {
        return Data.getPrettyProductType(key);
    };

    useEffect(() => {
        let options = [];

        Object.keys(props.allProducts).forEach((key) => {
            options.push(
                { label: getPrettyNameForType(key), value: key }
            );
        });
        setFilterOptions(options);
    }, [props.allProducts])


    const getProducts = () => {
        let products = {};

        let [showChip, setShowChip] = useState(false);

        let selectedProducts = { ...props.getter };

        Object.keys(props.allProducts).forEach((key) => {
            if (typeof filters === undefined || Object.keys(filters).length == 0 || filters.includes(key)) {
                props.allProducts[key].forEach(function (productName) {

                    let id = `${key}-${productName.replace(/\s+/g, '-').toLowerCase()}`;
                    if (!selectedProducts.hasOwnProperty(key)) selectedProducts[key] = {};

                    if (!selectedProducts[key].hasOwnProperty(id)) {
                        selectedProducts[key][id] = { 'checked': false };
                    }

                    if (productName.toUpperCase().includes(searchName.toUpperCase())
                        || (Constants.has_parent_co(productName)
                            && Constants.get_parent_co(productName).toUpperCase().includes(searchName.toUpperCase()))) {

                        /// need to store and retrieve checked products on change...and let filtering work properly
                        products[productName + key] =
                            <View onMouseEnter={() => { setShowChip(id); }}
                                onMouseLeave={() => { setShowChip(''); }}
                                style={[Constants.styles.flexRow, { alignItems: 'center', zIndex: -1, }]}>
                                <CheckField key={productName + key + refreshCount.current}
                                    name={id}
                                    id={id}
                                    label={productName}
                                    checked={selectedProducts[key][id].checked}
                                    onChange={(e) => {
                                        if (!selectedProducts[key].hasOwnProperty(id)) {
                                            selectedProducts[key][id] = { 'checked': false };
                                        }
                                        selectedProducts[key][id].checked = !selectedProducts[key][id].checked;
                                        selectedProducts = Data.doProductTranslations(selectedProducts);
                                        props.setter(selectedProducts);
                                        Data.savePreference('selectedProducts', selectedProducts);
                                        setForceClose(true);
                                    }}
                                    labelStyle={{ width: 'clamp(275px, 50vw, 100%)' }}
                                >
                                </CheckField>
                                {Constants.has_parent_co(productName) ? <Text style={[Constants.styles.badge, { marginLeft: 5, marginRight: 5, backgroundColor: Constants.colors.primaryColorBG, color: Constants.colors.paneColor }]}>{Constants.get_parent_co(productName)}</Text> : null}
                                {showChip == id ? <Text style={[Constants.styles.chip, { marginLeft: 10, marginTop: -4 }]}>{getPrettyNameForType(key)}</Text> : null}
                            </View >
                    }

                });
            }
        });

        let keys = Object.keys(products);
        keys.sort();
        return keys.map(function (x) { return products[x] });


    }

    useEffect(() => {
        if (props.visible) {
            if (searchField.current)
                searchField.current.focus();
        }
    }, [props.visible])

    let searchField = useRef(null);

    if (!props.isRegistered && props.visible) {
        props.setVisible(false);
        props.setTriggerFunction('register');

    }

    return (<>
        {props.visible ? <View style={[styles.modalBackdrop]}></View> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}>
                <ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.slideIn, styles.setAvailableProducts,]} onClick={() => { setForceClose(true); }}>
                    <Pane
                        id="setAvailableProducts"

                        heading='Set Available Products'
                        secondaryIcon={
                            <svg width="24" height="24" style={{ marginRight: 48 }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        bodyStyle={{
                            paddingLeft: isMobile ? 30 : 66,
                            minHeight: '72vh',
                        }}
                        bottomSpacing={36.88}
                        footerStyle={{ position: 'sticky', bottom: 0, backgroundColor: Constants.colors.paneColor }}

                        footer={
                            <Box style={[styles.actionButtonsWrapper, { alignItems: 'center', justifyContent: 'space-between' }, isMobile ? { marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0, flexDirection: 'column' } : null]}>
                                <View style={[isMobile ? Constants.styles.flex : Constants.styles.flexRow, { alignContent: 'left', marginLeft: isMobile ? 30 : 66 }, isMobile ? { marginLeft: 30, left: 0, position: 'absolute', marginBottom: 10, marginTop: -10 } : null]}>
                                    {
                                        (!props.preventAction.competitiveAnalysis)
                                            ? <BooleanSwitch key={`fexCompetitiveAnalysisMode-${refreshCount.current}`}
                                                get={props.fexCompetitiveAnalysisMode} set={props.setFEXCompetitiveAnalysisMode}
                                                onChange={(e) => {
                                                    let newVal = !props.fexCompetitiveAnalysisMode;
                                                    props.setFEXCompetitiveAnalysisMode(newVal);
                                                    Data.savePreference('fexCompetitiveAnalysisMode', newVal);
                                                    setForceClose(true);
                                                    refreshCount.current++
                                                }}
                                                label="Compare FEX Competitors"
                                            />
                                            : null}
                                </View>
                                <View style={[Constants.styles.flexRow, { alignItems: 'center' }, isMobile ? { flexDirection: 'column-reverse', alignItems: 'center', margin: 0, marginTop: 30, } : null]}>
                                    <Text style={{ marginRight: isMobile ? 0 : 26, color: Constants.colors.textColor, }}>Changes are auto saved</Text>
                                    <Pressable id="closeRegistration" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                        onPress={() => { props.setVisible(!props.visible) }}>
                                        <Text style={Constants.styles.secondaryButtonText}>Close</Text>
                                    </Pressable>
                                </View>
                            </Box>
                        }

                    >
                        <View style={[{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginBottom: 18, marginLeft: 10, flexWrap: 'wrap', width: 'clamp(100%, 1vw, 577)', maxWidth: 659 }]}>
                            <View style={[{ marginLeft: -10, display: 'flex', alignItems: 'flex-start', flexDirection: 'row', marginBottom: 18, flexWrap: 'wrap', width: '100%', maxWidth: 659 }]}>
                                <View style={{ marginRight: 9, marginBottom: 18, flexWrap: 'wrap', display: 'flex' }}>
                                    <TextInputField
                                        width={isMobile ? '70vw' : 284}
                                        prefixAdornment='search' correctTypos={props.getTyposList}
                                        refVal={searchField}
                                        style={[Constants.styles.textControl, { textTransform: 'uppercase', width: isMobile ? '70vw' : 284 }]} val={searchName}
                                        syncWithGetter={1} getter={searchName} value={searchName} setter={setSearchName}
                                    ></TextInputField>
                                </View>

                                <FilterField options={filterOptions} key={`filter-${filterRefresh.current}`}
                                    getter={filters} setter={setFilters} focuser={searchField}
                                    blankText={filters.length == 0 ? "Filter products" : `Filter products (${filters.length})`} width={isMobile ? '70vw' : 232}
                                    onChange={() => {
                                        refreshCount.current++;
                                    }}
                                    forceClose={forceClose} setForceClose={setForceClose}
                                    dropdownStyle={isMobile ? { left: 0, marginTop: 60 } : null}
                                ></FilterField>
                            </View>
                        </View>

                        <View style={[styles.contents, { zIndex: -5, minHeight: 170 }]}>
                            <View style={{ overflow: 'visible', zIndex: -5, }}>
                                {getProducts() /* filters automatically considered */}
                            </View>
                        </View>
                    </Pane>
                </View>
            </View>
        </Modal >
    </>);
}

export function PlanInfoSlideIn(props) {

    // @TODO add logic for core processing with state derivatives and get_products_str()


    let [checked, setChecked] = useState({});
    let [filters, setFilters] = useState([]);
    let [searchName, setSearchName] = useState('');
    let [filterOptions, setFilterOptions] = useState([]);
    let [forceClose, setForceClose] = useState(false);


    let refreshCount = useRef(0);
    let filterRefresh = useRef(0);

    let [isEnterpriseWithCallback, setIsEnterpriseWithCallback] = useState(Data.isEnterpriseWithCallback());

    useEffect(() => {
        if (props.visible) {
            if (searchField.current)
                searchField.current.focus();
        }
    }, [props.visible])

    useEffect(() => {
        refreshCount.current++;
    })

    let searchField = useRef(null);
    let resultObject = props.result.object;
    if (!props.result.object) return;

    let [carrier, product, plan] = Data.getCarrierProductPlanFromObject({ 'results': resultObject, 'identifier': props.result.identifier });
    let logoURI = Data.getLogo(carrier, product, props.dataStringLogosDBRef);


    let planInfoKeys = Object.keys(resultObject.planinfo);
    planInfoKeys.sort();
    planInfoKeys = planInfoKeys.filter(e => !['eapp', plan].includes(e.toLowerCase()));

    let eAppLink = resultObject.planinfo.eApp;
    if (!eAppLink) {
        resultObject.planinfo.eapp;
    }
    try {
        eAppLink = decodeURIComponent(eAppLink);
    } catch (e) { }

    let isDiscontinued = false;
    if (resultObject.planinfo.hasOwnProperty('*Discontinued')) {
        isDiscontinued = true;
    }

    let price = Data.prettyPrice(resultObject.result.replace(/\s*-\s*.*/, '').replace('  ', ' '));

    let currentReadableIndex = 0;
    if (props.planInfoOptionsRef.current && props.planInfoOptionsRef.current.length > 0)
        props.planInfoOptionsRef.current.filter(e => e).forEach((obj, idx) => {
            if (obj.results.index == resultObject.index) {
                currentReadableIndex = idx + 1; // done
            }
        })




    const nextOption = () => {

        let idx = resultObject.index;
        do {
            idx++;
            if (idx < 0) idx = 0;
            if (idx > props.planInfoOptionsRef.current.length - 1) idx = props.planInfoOptionsRef.current.length - 1;
            if (props.planInfoOptionsRef.current[idx] !== undefined)
                props.setPlanInfoChoice({
                    'useBudget': props.useBudget,
                    'object': props.planInfoOptionsRef.current[idx].results,
                    'identifier': props.planInfoOptionsRef.current[idx].identifier,
                    'amounts': props.useBudget ? props.mbsRef.current : props.fvsRef.current,
                });
        } while (props.planInfoOptionsRef.current[idx] == undefined && idx > 0 && idx < props.planInfoOptionsRef.current.length)
    }

    const previousOption = () => {
        let idx = resultObject.index;
        do {
            idx--;
            if (idx < 0) idx = 0;
            if (props.planInfoOptionsRef.current[idx] !== undefined)
                props.setPlanInfoChoice({
                    'useBudget': props.useBudget,
                    'object': props.planInfoOptionsRef.current[idx].results,
                    'identifier': props.planInfoOptionsRef.current[idx].identifier,
                    'amounts': props.useBudget ? props.mbsRef.current : props.fvsRef.current,
                });
        } while (props.planInfoOptionsRef.current[idx] == undefined && idx > 0)
    }

    const firstOption = () => {

        let idx = 0;
        if (props.planInfoOptionsRef.current[idx] !== undefined)
            props.setPlanInfoChoice({
                'useBudget': props.useBudget,
                'object': props.planInfoOptionsRef.current[idx].results,
                'identifier': props.planInfoOptionsRef.current[idx].identifier,
                'amounts': props.useBudget ? props.mbsRef.current : props.fvsRef.current,
            });
    }

    const lastOption = () => {

        let idx = props.planInfoOptionsRef.current.length - 1; // base 1 needs to be converted
        if (props.planInfoOptionsRef.current[idx] !== undefined)
            props.setPlanInfoChoice({
                'useBudget': props.useBudget,
                'object': props.planInfoOptionsRef.current[idx].results,
                'identifier': props.planInfoOptionsRef.current[idx].identifier,
                'amounts': props.useBudget ? props.mbsRef.current : props.fvsRef.current,
            });
    }


    let planInfoCurrentPlanKey = Object.keys(resultObject.planinfo).filter((e) => e.toLowerCase() == plan.replace('*', ''));
    let planExplanationElems = [];
    let planExplanation = '';
    if (planInfoCurrentPlanKey.length >= 1) {
        planInfoCurrentPlanKey.forEach((key) => {
            planExplanationElems.push(resultObject.planinfo[key])
        });
        planExplanation = planExplanationElems.join('; ');
    }

    const ShowPricing = () => {
        if (Object.keys(resultObject.pricing.default).length == 1) return;

        let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;

        let keys = ['default'].concat(Object.keys(resultObject.pricing).filter((e) => e != 'default').sort()); // note default is the pricing option for the current expected plan

        let [activePricingTab, setActivePricingTab] = useState('default');

        let k = [];
        let modals = Object.keys(resultObject.pricing[activePricingTab]);
        modals.sort();
        try {
            Object.keys(resultObject.pricing[activePricingTab]).forEach((modal) => { let price = resultObject.pricing[activePricingTab][modal]; return k.push([modal, price.replace('$', '')]) });
            k = k.sort(function (a, b) { return a[1] - b[1] });
        } catch (e) { }
        try {
            modals = k.map((arr) => arr[0]);
        } catch (e) { }

        return (<>
            <View style={{ marginLeft: 0 }}>
                <View style={{ borderBottomWidth: 1, borderColor: Constants.colors.disabledButton, marginBottom: 25 }}>
                    <View style={{ marginBottom: Constants.leftOffset }}>
                        <View style={[Constants.styles.flexRow, { marginBottom: Constants.leftOffset, paddingRight: Constants.leftOffset, }]}>
                            <Text style={{ fontWeight: '700', fontSize: Constants.fontSizes.planInfoLabel, marginRight: Constants.leftOffset / 2 }}>Pricing:</Text>
                            <Text style={{ fontWeight: '400', fontSize: Constants.fontSizes.planInfoLabel }}>We expect <Text style={{ fontWeight: '700', }}>{Data.prettyFormatPlanName(plan)}</Text> to be the best plan possible based on the provided information.  Other eligible options are displayed for convenience.</Text>
                        </View>


                        <View style={[Constants.styles.flexRow, { borderBottomWidth: 1, borderColor: Constants.colors.disabledButton, paddingRight: Constants.leftOffset, overflowX: 'auto' }]}>
                            {
                                keys.map((option, idx) => {
                                    return <Pressable onPress={() => { setActivePricingTab(option); }}
                                        style={[{
                                            marginLeft: idx == 0 ? -1 * Constants.styles.leftOffset : Constants.styles.leftOffset,
                                            paddingLeft: 12, paddingRight: 12,
                                            marginRight: Constants.styles.leftOffset,
                                            height: 40, minWidth: 90, justifyContent: 'center', paddingBottom: 22,
                                        },
                                        Constants.styles.flex, Constants.styles.verticalCenter, Constants.styles.tab,
                                        activePricingTab == option ? Constants.styles.activeProductTab : Constants.styles.link]}>
                                        {option == 'default' ? Data.prettyFormatPlanName(plan) : Data.prettyFormatPlanName(option)}
                                    </Pressable>
                                })

                            }


                        </View>
                        <View style={[Constants.styles.flex, { paddingTop: Constants.leftOffset, marginLeft: 0 }]}>
                            {resultObject.hasOwnProperty('planinfo') && resultObject.planinfo.hasOwnProperty(activePricingTab.toUpperCase()) ? <Text style={[styles.planInfoDescription, { marginLeft: 30, marginRight: 30, fontWeight: '500', fontStyle: 'italic' }]}>{resultObject.planinfo[activePricingTab.toUpperCase()]}</Text> : null}
                            {modals.map((modal) => {
                                return <View style={{ marginLeft: Constants.leftOffset }}>
                                    <Text style={{ fontWeight: '700' }}>{modal.toUpperCase()}</Text>
                                    <Text>{resultObject.pricing[activePricingTab][modal]}</Text>
                                    <Text> </Text>
                                </View>
                            })}
                        </View>
                    </View>
                </View>
            </View >

        </>)
    }

    if (!props.planInfoOptionsRef.current || props.planInfoOptionsRef.current.length == 0) {
        return props.setVisible(false);
    }

    let isMobile = Dimensions.get('window').width < Constants.mobileMenuBreakpoint;
    return (<>
        {props.visible ? <>
            <KeyboardShortcut moddedShortcut="ArrowDown" callback={(e) => { nextOption(); }} />
            <KeyboardShortcut moddedShortcut="ArrowUp" callback={(e) => { previousOption(); }} />
            <View style={[styles.modalBackdrop]}></View>
        </> : null}
        <Modal
            animationType="none"
            transparent={true}
            visible={props.visible}
            onRequestClose={() => {
                props.setVisible(!props.visible);
            }}
        >
            <View style={styles.centeredView}>
                <ModalBackdropClickDetect onPress={() => { props.setVisible(false) }} />
                <View style={[styles.modalView, styles.slideIn, styles.setAvailableProducts,]} onClick={() => { setForceClose(true); }}>
                    <Pane
                        id="plan-info"

                        heading={`${resultObject.result.replace(/Rank \d+/, '').replace('  ', ' ').replace(/^\s-\s/, '')}`}
                        secondaryIcon={
                            < svg width="24" height="24" style={{ marginRight: 48 }} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.19824 8.98047C9.63887 8.54453 10.3514 8.54453 10.7498 8.98047L12.9529 11.1883L15.1982 8.98047C15.6389 8.54453 16.3514 8.54453 16.7498 8.98047C17.2279 9.42109 17.2279 10.1336 16.7498 10.532L14.5842 12.7352L16.7498 14.9805C17.2279 15.4211 17.2279 16.1336 16.7498 16.532C16.3514 17.0102 15.6389 17.0102 15.1982 16.532L12.9529 14.3664L10.7498 16.532C10.3514 17.0102 9.63887 17.0102 9.19824 16.532C8.7623 16.1336 8.7623 15.4211 9.19824 14.9805L11.4061 12.7352L9.19824 10.532C8.7623 10.1336 8.7623 9.42109 9.19824 8.98047ZM24.9951 12.7773C24.9951 19.4055 19.6232 24.7773 12.9951 24.7773C6.36699 24.7773 0.995117 19.4055 0.995117 12.7773C0.995117 6.14922 6.36699 0.777344 12.9951 0.777344C19.6232 0.777344 24.9951 6.14922 24.9951 12.7773ZM12.9951 3.02734C7.60918 3.02734 3.24512 7.39141 3.24512 12.7773C3.24512 18.1633 7.60918 22.5273 12.9951 22.5273C18.3811 22.5273 22.7451 18.1633 22.7451 12.7773C22.7451 7.39141 18.3811 3.02734 12.9951 3.02734Z"
                                    fill={Constants.colors.captionColor} />
                            </svg>
                        }
                        secondaryPress={() => { props.setVisible(!props.visible); }}
                        style={{
                            width: '100%',
                        }}
                        bodyStyle={{
                            paddingLeft: isMobile ? 30 : 66,
                        }}
                        bottomSpacing={36.88}
                        footerStyle={{ position: 'sticky', bottom: 0, backgroundColor: Constants.colors.paneColor }}

                        footer={<View style={[Constants.styles.flexRow, { flex: 1, justifyContent: 'space-between' }]}>
                            <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { marginLeft: Constants.leftOffset }]}>
                                <Pressable onPress={() => { previousOption() }} style={[styles.activePage, styles.centeredIcon, { marginRight: 9 }, currentReadableIndex == 1 ? styles.disabledPlanInfoScrollButton : null]}>
                                    <Icon as={Feather} name={'chevron-up'} style={{ marginLeft: 0.5, width: 16, height: 16 }} color={Constants.colors.paneColor} />
                                </Pressable>

                                {props.planInfoOptionsRef.current && props.planInfoOptionsRef.current.length > 0 ?
                                    <Text nativeID="planInfoScrollText">{currentReadableIndex} of {props.planInfoOptionsRef.current.filter(e => e).length}</Text>
                                    : null}
                                <Pressable onPress={() => { nextOption() }} style={[styles.activePage, styles.centeredIcon, { marginLeft: 9 }, currentReadableIndex == props.planInfoOptionsRef.current.filter(e => e).length ? styles.disabledPlanInfoScrollButton : null]}>
                                    <Icon as={Feather} name={'chevron-down'} style={{ marginLeft: 0.5, marginTop: 2, width: 16, height: 16 }} color={Constants.colors.paneColor} />

                                </Pressable>
                            </View>
                            <Box style={styles.actionButtonsWrapper}>
                                <Pressable id="closePlanInfo" style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}
                                    onPress={() => { props.setVisible(!props.visible) }}>
                                    <Text style={Constants.styles.secondaryButtonText}>Close</Text>
                                </Pressable>
                                {isEnterpriseWithCallback ?
                                    <Link id="eAppLaunch" disabled={plan == 'EXCLUDE'} nullButton={1} style={[Constants.styles.actionButton, Constants.styles.primaryButton, { lineHeight: 'auto', textAlign: 'center' }, plan == 'EXCLUDE' ? { opacity: 0.6 } : null]} onPress={() => {
                                        Data.performAPICallback({
                                            carrier: carrier,
                                            product: product,
                                            plan: plan,
                                            currentCase: props.currentCase,
                                            monthlyPrice: price == 'N/A' ? null : price,
                                            link: props.getLink,
                                            planInfoId: {
                                                'object': resultObject,
                                                'identifier': props.identifier,
                                                'useBudget': props.useBudget,
                                                'amounts': props.useBudget ? props.mbsRef.current : props.fvsRef.current,
                                            },
                                            dataToText: props.dataToText,
                                            activeGroupTab: props.activeGroupTabRef.current,
                                            activeMoneyTab: props.activeMoneyTabRef.current,
                                        })
                                    }}>{plan == 'EXCLUDE' ? 'Ineligible' : 'Select'}</Link>
                                    : eAppLink ? <Link id="eAppLaunch" style={[Constants.styles.actionButton, Constants.styles.primaryButton, { lineHeight: 'auto' }]} href={eAppLink}>
                                        Open eApp
                                    </Link> : null}
                            </Box>
                        </View>
                        }

                    >
                        <View style={{ borderBottomWidth: 1, borderColor: Constants.colors.disabledButton, marginBottom: 38 }} >
                            <View style={[Constants.styles.flexRow, { justifyContent: 'space-between', flexWrap: 'wrap', alignItems: 'center', marginBottom: Constants.leftOffset }]} key={`heading-${resultObject.result.toLowerCase().replace(/[^a-z]/i)}`}>
                                <View style={[Constants.styles.flexRow, { alignItems: 'center', flexWrap: 'wrap', width: 'fit-content' }]}>
                                    <View style={{ width: 98, marginRight: 20, marginBottom: 10 }}>
                                        <Image style={{ width: 98, height: 58 }}
                                            resizeMode="contain"
                                            source={{ uri: logoURI }} />
                                    </View>
                                    <View style={{ maxWidth: 250, width: 250, marginBottom: 10 }}>
                                        <Text style={{ fontWeight: '700', fontSize: Constants.fontSizes.headingText }}>
                                            {
                                                resultObject.hasOwnProperty('plan-group') ?
                                                    `${resultObject.result.replace(/.*\s*-\s*/, '').replace(/Rank \d+/, '').replace('  ', ' ').replace(new RegExp(resultObject['plan-group'].replace(/\-/g, '.'), 'ig'), '').replace(/\s*:/g, ':').replace(/\s*:\s*$/g, '').trim()}`
                                                    : `${resultObject.result.replace(/.*\s*-\s*/, '').replace(/Rank \d+/, '').replace('  ', ' ').trim()}`
                                            }</Text>
                                        {resultObject.hasOwnProperty('plan-group')
                                            ?
                                            <Text style={{ fontWeight: '500', textTransform: 'capitalize', fontSize: Constants.fontSizes.headingText }}>
                                                {`${resultObject['plan-group'].replace(/\-/g, ' ').toLowerCase()}`}</Text>
                                            : null}
                                    </View>
                                </View>
                                {resultObject.result.toLowerCase().includes('exclude') ?
                                    <View style={{ marginRight: Constants.leftOffset * 1.5, alignItems: 'center' }}>
                                        <Text style={{ fontWeight: '700', fontSize: Constants.fontSizes.planInfoPrimaryPrice }}>
                                            N/A</Text>
                                    </View>
                                    :
                                    <View style={{ marginRight: Constants.leftOffset * 1.5, alignItems: 'center' }}>
                                        <Text style={{ fontWeight: '700', fontSize: Constants.fontSizes.planInfoPrimaryPrice }}>
                                            {price}</Text>
                                        {props.result.useBudget ?
                                            (price ? <Text>Death Benefit</Text> :
                                                null)
                                            : null}
                                    </View>
                                }
                            </View>

                            <Text style={styles.planInfoDescription}>{planExplanation ? planExplanation : null}</Text>
                            <View style={styles.planInfoTags}>
                                <ResultBadges isOldCase={props.isOldCase} key={`badges-${refreshCount.current}`} hideTooltips={true} data={{ 'results': resultObject, 'identifier': props.result.identifier }} selectedProducts={props.selectedProducts} rank={resultObject.rank} carrier={carrier} product={product} isDiscontinued={isDiscontinued} />
                            </View>
                        </View>
                        <ShowPricing />
                        <View style={Constants.styles.flex}>
                            {planInfoKeys.map((key) => {

                                let planOptions = ['default'].concat(Object.keys(resultObject.pricing).filter((e) => e != 'default').sort()); // note default is the pricing option for the current expected plan
                                if (planOptions.includes(key.toLowerCase())) return;
                                if (planOptions.map(x => Data.zysys_make_key(x)).includes(Data.zysys_make_key(key.toLowerCase()))) return;
                                if (Data.zysys_smart_cmp(plan, key)) return;
                                return (
                                    <View style={[Constants.styles.flex, { marginBottom: 10, fontWeight: '400', fontSize: Constants.fontSizes.input, lineHeight: Constants.leftOffset }]}>
                                        <Text style={[{ fontWeight: '700', whiteSpace: 'nowrap', fontSize: Constants.fontSizes.input, lineHeight: Constants.leftOffset }, key.match(/^\d+\.\s+/) ? { textTransform: 'capitalize' } : null]}>
                                            {
                                                key.match(/^\d+\.\s+/) ? key.replace(/^\d+\.\s+/, '').toLowerCase() : key
                                            }: </Text>
                                        <Text style={{ fontWeight: '400', paddingRight: Constants.leftOffset * 1.5, fontSize: Constants.fontSizes.input, lineHeight: Constants.leftOffset }}>{resultObject.planinfo[key]}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </Pane>
                </View>
            </View >
        </Modal >
    </>);
}

const styles = StyleSheet.create({
    disabledPlanInfoScrollButton: {
        backgroundColor: Constants.colors.disabledButton,
        color: Constants.colors.captionColor,
        cursor: 'not-allowed',
    },
    centeredIcon: {
        display: 'flex',
        flex: 1,
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
    },
    activePage: {
        borderRadius: 200,
        backgroundColor: Constants.colors.primaryButton,
        color: 'white',
        width: 20.5,
        height: 20.5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 4,
        paddingBottom: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    contents: {
        overflow: 'visible',
        marginRight: 20,
    },
    fullWidth: {
        borderRadius: Constants.borderRadius,
        width: '100%',
    },
    slideIn: {
        height: '100%',
        width: '50%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    setAvailableProducts: {
        width: 718,
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
        maxHeight: '100%',
    },
    defaultStateModal: {
        minWidth: 511.5,
        width: 511.5,
        borderRadius: Constants.borderRadius,
        overflow: 'visible',
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        marginTop: 0,
    },
    modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: Constants.borderRadius,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        boxShadow: Constants.boxShadow,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    modalBackdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: Constants.colors.modalBackdrop,
        zIndex: 5000,
        justifyContent: 'center',
    },
    modal: {
        zIndex: 5001,
        display: 'flex',
    },
    actionButtonsWrapper: {
        flex: 1,
        textAlign: 'right',
        flexDirection: 'row',
        alignContent: 'right',
        alignItems: Platform.OS !== 'web' ? 'center' : 'right',
        justifyContent: Platform.OS !== 'web' ? 'center' : 'right',
        marginBottom: 32,
        marginRight: 42,
        marginTop: 30,
    },


    bkgBird: {
        height: 512 * .65,
        width: 512 * .65,
        position: 'absolute',
        zIndex: -5000,
        margin: 0,
        padding: 0,
        right: -105,
        top: -90,
        opacity: .2,

    },
    fullWidth: {
        borderRadius: Constants.borderRadius,
        width: '100%',
    },
    setAvailableProducts: {
        width: 718,
        maxWidth: '100%',
        borderRadius: Constants.borderRadius,
        overflowY: 'scroll',
    },
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-end",
        marginTop: 0
    },
    modalView: {
        margin: 0,
        backgroundColor: "white",
        borderRadius: Constants.borderRadius,
        alignItems: "right",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        boxShadow: Constants.boxShadow,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2
    },
    buttonOpen: {
        backgroundColor: "#F194FF",
    },
    buttonClose: {
        backgroundColor: "#2196F3",
    },
    textStyle: {
        color: "white",
        fontWeight: "bold",
        textAlign: "center"
    },
    modalText: {
        marginBottom: 15,
        textAlign: "center"
    },
    modalBackdrop: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        backgroundColor: Constants.colors.modalBackdrop,
        zIndex: 5000,
        justifyContent: 'center',
    },
    modal: {
        zIndex: 5001,
        display: 'flex',
    },
    actionButtonsWrapper: {
        flex: 1,
        textAlign: 'right',
        flexDirection: 'row',
        alignContent: 'right',
        alignItems: Platform.OS !== 'web' ? 'center' : 'right',
        justifyContent: Platform.OS !== 'web' ? 'center' : 'right',
        marginBottom: 32,
        marginRight: 42,
        marginTop: 30,
    },

    link: {
        marginTop: 23
    },

    planInfoDescription: {
        fontSize: Constants.fontSizes.input,
        fontWeight: '500',
        marginBottom: Constants.leftOffset,
        marginTop: 0,
        paddingRight: Constants.leftOffset,
    },
    planInfoTags: {
        marginBottom: 40,
        paddingRight: Constants.leftOffset,
    }
});

// src/ToggleField.js

import React, { useEffect, useState, useRef } from 'react'
import { View, StyleSheet, Pressable, Button, Platform } from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import TextInputField, { SelectField, RadioField, SwitchButton, BooleanSwitch } from './Input';
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Box, IconButton } from 'native-base';
import * as Data from './libs/data';
import Text from './Text';

import { Select, CheckIcon, ChevronDownIcon, Radio, Icon } from "native-base";
const styles = new StyleSheet({
    margins: {
        marginLeft: 14,
        marginRight: 14
    },
});
function ToggleField(props) {


    const [displayed, setDisplayed] = props.displayed ? [props.displayed, props.setDisplayed] : useState(1);
    let internalPane = props.internalPane ? props.internalPane : 0;
    let margin = 14 - (internalPane * 4);
    if (margin < 0) margin = 0;

    let field1 = useRef(null);
    let field2 = useRef(null);

    useEffect(() => {
        if (props.setter) {
            props.setter(displayed);
        }

    }, [displayed])

    let [autoFocus1, setAutoFocus1] = useState(0);
    let [autoFocus2, setAutoFocus2] = useState(0);
    let toggle = (idx) => {
        if (props.readonly) return;
        setDisplayed(idx); if (props.onToggle) props.onToggle();
        if (idx == 1) {
            setAutoFocus1(true);
            setAutoFocus2(false);
        }

        if (idx == 2) {
            setAutoFocus2(true);
            setAutoFocus1(false);
        }
    }

    let one = <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%' }}>
        {React.cloneElement(props.one, {
            readonly: props.readonly,
            autoFocus: autoFocus1, ref: field1, refVal: field1, onBlur: (e) => { setAutoFocus1(false); setAutoFocus2(false); },
            onKeyUp: (e) => { if (e.key == '!') { e.target.value = e.target.value.replace(/!/g, ''); toggle(2); } }
        })}
        <Text style={[Constants.styles.textControlCaption, { marginLeft: margin }, props.readonly ? { display: 'none' } : null]}>or
            <Pressable style={{ marginLeft: margin }} onKeyUp={(e) => { if (e.key == '!') { toggle(2) } }} onPress={() => { toggle(2) }}>
                <Text selectable={false} style={[Constants.styles.link, { paddingTop: 5 }]}>{props.switchToTwo}</Text>
            </Pressable></Text>
    </View>;
    let two = <View style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', maxWidth: '100%' }}>
        {React.cloneElement(props.two, { readonly: props.readonly, autoFocus: autoFocus2, ref: field2, refVal: field2, onBlur: (e) => { setAutoFocus1(false); setAutoFocus2(false); }, onKeyUp: (e) => { if (e.key == '!') { e.target.value = e.target.value.replace(/!/g, ''); toggle(1); } } })}
        <Text style={[Constants.styles.textControlCaption, { marginLeft: margin }, props.readonly ? { display: 'none' } : null]}>or
            <Pressable style={{ marginLeft: margin }} onKeyUp={(e) => { if (e.key == '!') { toggle(1) } }} onPress={() => { toggle(1) }}>
                <Text selectable={false} style={[Constants.styles.link, { paddingTop: 5 }]}>{props.switchToOne}</Text>
            </Pressable></Text>
    </View>;

    return (
        <View key='toggle-field' style={[{ display: 'flex', flexDirection: 'row', alignItems: 'center' }]}>
            {displayed == 1 ? one : two}
        </View>
    );
}

export default ToggleField


// src/BasicInformation.js

import React, { useEffect, useState, useRef } from 'react'
import { View, StyleSheet, Image, Pressable, Platform, ActivityIndicator, useWindowDimensions } from 'react-native'
import { Tooltip } from 'native-base'
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Pane from './Pane';
import Row from './Row';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Text from './Text';

import * as Constants from '../theme';
import * as Data from "./libs/data";
import Link from './Link';
import { BooleanSwitch, FilterField, RadioField, SelectField } from './Input';
import KeyboardShortcut from './KeyboardShortcut';
import ResultBadges from './ResultBadges';
import SAHProgressBar from './ProgressBar';

const ShowTabs = (props) => {
    if (!props.productTypes || props.productTypes.length < 1) return;
    if (props.activeProductTab == '') props.setActiveProductTab(props.productTypes[0]);
    if (props.activeMoneyTab == '' && props.moneyOptions.length > 0) {
        props.setActiveMoneyTab(props.moneyOptions[0].replace(/[^\d\.]/g, ''));
    }

    let groupOptions = [];
    if (props.productGroups) {
        // uniq the list with Set
        Array.from(new Set(props.productGroups)).sort().forEach((group) => {
            groupOptions.push({ label: group.replace(/\-/g, ' ').toLowerCase().replace('rop', 'ROP'), value: group, id: group })
        });
        if (props.activeGroupTab == '' && groupOptions.length > 0) {
            props.setActiveGroupTab(groupOptions[0].value);
        }

    }

    let moneyOptions = [];
    if (props.moneyOptions && props.activeProductTab != 'medsup') {
        // uniq the list with Set
        Array.from(new Set(props.moneyOptions.map((money) => { return money.replace(/[^\d\.]/g, '') }))).sort(function (a, b) { return a - b }).forEach((money) => {
            moneyOptions.push({ value: money, label: Data.prettyPrice(money).replace('\.00', ''), id: money })
        });
    }

    let groupLabel = '';
    switch (props.activeProductTab) {
        case 'term':
            groupLabel = 'Term';
            break;
        case 'medsup':
            groupLabel = 'Plan';
            break;
    }

    useEffect(() => {
        let groupOptions = [];
        if (props.productGroups) {
            // uniq the list with Set
            Array.from(new Set(props.productGroups)).sort().forEach((group) => {
                groupOptions.push({ label: group.replace(/\-/g, ' ').toLowerCase().replace('rop', 'ROP'), value: group, id: group })
            });

            if (groupOptions.length > 0 && !groupOptions.includes(props.activeGroupTab)) {
                props.setActiveGroupTab(groupOptions[0].value);
            } else {
                props.setActiveGroupTab('');
            }
        }
    }, [props.activeProductTab])


    return (<>
        <View style={[Constants.styles.flexRow, { overflow: 'auto', borderBottomWidth: 1, marginLeft: -1 * Constants.leftOffset, borderColor: Constants.colors.disabledButton, paddingLeft: Constants.leftOffset, paddingRight: Constants.leftOffset, }]}>
            {
                props.productTypes.map((type) => {
                    return <Pressable onPress={() => { props.setActiveProductTab(type); }}
                        style={[{ marginLeft: Constants.styles.leftOffset, paddingLeft: 16, paddingRight: 16, marginRight: Constants.styles.leftOffset, height: 40, minWidth: 90, justifyContent: 'center', paddingBottom: 22, }, Constants.styles.flex, Constants.styles.verticalCenter, Constants.styles.tab, props.activeProductTab == type ? Constants.styles.activeProductTab : Constants.styles.link]}>
                        <>{Constants.productTypeShortnames[type]}</>
                    </Pressable>
                })

            }
        </View>

        {moneyOptions.length > 0 ?
            <View style={[Constants.styles.flexRow, { alignItems: 'start', gap: 15, width: '100%', paddingBottom: Constants.leftOffset, marginTop: Constants.leftOffset, textTransform: 'capitalize', marginLeft: -1 * Constants.leftOffset, paddingLeft: Constants.leftOffset, paddingRight: Constants.leftOffset, }, groupOptions.length > 0 ? { paddingBottom: 0 } : { borderBottomWidth: 1, borderColor: Constants.colors.disabledButton },]}>
                <Text selectable={false} style={{ width: 77, marginRight: 28, fontWeight: '700', marginTop: 2, display: 'flex', alignItems: 'center' }}>{props.moneyOptionLabel}</Text>
                <RadioField key={`set-money-`} horizontal={1}
                    style={{ maxWidth: 480, width: '100%', flexWrap: 'wrap', gap: "15px 30px" }} optionStyle={{ flexWrap: 'wrap', width: '100%', maxWidth: 480, }}
                    labelStyle={{ width: 94, fontSize: Constants.fontSizes.label, fontWeight: '500' }}
                    getter={props.activeMoneyTab} setter={props.setActiveMoneyTab} options={moneyOptions}
                ></RadioField>
            </View>
            : null
        }

        {
            groupOptions.length > 0 ?
                <View style={[Constants.styles.flexRow, { paddingBottom: Constants.leftOffset, marginTop: Constants.leftOffset, textTransform: 'capitalize', borderBottomWidth: 1, marginLeft: -1 * Constants.leftOffset, borderColor: Constants.colors.disabledButton, paddingLeft: Constants.leftOffset, paddingRight: Constants.leftOffset, }]}>
                    <Text selectable={false} style={{ width: 77, marginRight: 28, fontWeight: '700', marginTop: 2 }}>{groupLabel}</Text>
                    <RadioField key={`set-group-`} horizontal={1} style={{ maxWidth: 480, flexWrap: 'wrap', gap: "15px 30px" }} optionStyle={{ flexWrap: 'wrap', maxWidth: 480 }}
                        labelStyle={{ width: 94, fontSize: Constants.fontSizes.label, fontWeight: '500' }}
                        getter={props.activeGroupTab} setter={props.setActiveGroupTab} options={groupOptions}
                    ></RadioField>
                </View>
                : null
        }

    </>);
}


function Results(props) {

    const [filterOptions, setFilterOptions] = useState([
        { 'label': 'Payment Methods', 'value': '' },
        ...Constants.PaymentMethodFilters,
        { 'label': 'Riders', 'value': '' },
        ...Constants.RiderFilters,
        { 'label': 'Requirements', 'value': '' },
        ...Constants.RequirementFilters,

    ]);
    let [forceCloseFilters, setForceCloseFilters] = useState(false);
    const refreshCount = useRef(0);
    const filterRefresh = useRef(0);
    let resultKeys = useRef([]);


    let [progress, setProgress] = useState(0);
    useEffect(() => {
        if (!props.processing) {
            setProgress(1);
            return;
        }

        let prog = progress;
        if (props.startProgressTimer == false) {
            setProgress(1);
            return;
        } else {
            //prog = 0;
        }

        let intervalTiming = Constants.timeout + ((Constants.timeout / 2) - (Constants.timeout * prog));
        setTimeout(() => {

            if (prog >= 1) {
                setProgress(1);
                props.setStartProgressTimer(false);
            }

            setProgress(Math.min(prog + 0.001, .99));

        }, intervalTiming);

    }, [props.startProgressTimer, progress, props.processing])

    const OutdatedWarning = (props) => {
        if (props.isReadonly.current) return;
        if (props.presetResultsJSON) return;
        if (props.prevCase.current == null
            || props.prevCase.current.length < 4
            || JSON.stringify(props.prevCase.current) == JSON.stringify(props.currentCase.current)
            || props.processing) {
            return;
        }

        if (props.forceHideOutdated) return; // this should be reset with any update in case (since that'll trigger a parent state change)
        return (
            <View style={{
                position: 'absolute', height: '100%', width: '100%', borderRadius: Constants.borderRadius, zIndex: 5000, backgroundColor: Constants.colors.modalBackdrop, alignItems: 'center'
            }}>
                <Pane key={`outdated-warning-${activeGroupRefRenderCount.current}`} heading="Outdated Case" style={{ marginTop: Constants.leftOffset * 3, marginRight: Constants.leftOffset, marginLeft: Constants.leftOffset, width: 511 }}
                    footer={<>
                        <Pressable onPress={() => { props.prevCase.current = props.currentCase.current; props.setForceHideOutdated(true); }} style={[Constants.styles.actionButton, Constants.styles.secondaryButton]}>
                            <Text selectable={false} style={[Constants.styles.secondaryButtonText, { fontWeight: '700' }]}>See Existing Quote</Text>
                        </Pressable>
                        <Pressable id="analyze" onPress={() => { props.setTriggerFunction('analyze') }} style={[Constants.styles.actionButton, Constants.styles.primaryButton]}>
                            <Text selectable={false} style={[Constants.styles.primaryButton, { fontWeight: '700' }]}>Get Updated Quote</Text>
                        </Pressable>
                    </>
                    }
                    footerStyle={{ padding: 30, justifyContent: 'end' }}
                >
                    <Text selectable={false} >Please click the 'Get Updated Quote' button below to refresh</Text>
                    <Text selectable={false} >your quote based on the latest changes to your case details.</Text>
                    <Text selectable={false} > </Text>

                    <Text selectable={false} >This will ensure the information and pricing you receive are</Text>
                    <Text selectable={false} >up-to-date and accurate.</Text>
                </Pane >
            </View >
        )
    }

    /* filter states */
    let [filters, setFilters] = useState([]);

    /* plan info states */
    let [planInfoChoice, setPlanInfoChoice] = useState(null);
    let [showPlanInfo, setShowPlanInfo] = useState(true);
    let [planInfoMover, setPlanInfoMover] = useState(0);

    /* tab states */
    let [activeProductTab, setActiveProductTab] = useState('');
    let [activeMoneyTab, setActiveMoneyTab] = useState('');
    let [activeGroupTab, setActiveGroupTab] = useState('');
    let activeGroupRef = useRef('');
    let activeGroupRefRenderCount = useRef(0);

    let [page, setPage] = useState(1);
    let [showCompetitors, setShowCompetitors] = useState(props.showCompetitors);

    let moneyTabs = useRef([]);
    let fvsRef = useRef([]);
    let mbsRef = useRef([]);
    let prevRawJSON = useRef('');
    let modeFVRef = useRef(null);

    let [problemRunningCase, setProblemRunningCase] = useState(false);

    useEffect(() => {
        props.setShowCompetitors(showCompetitors);
    }, [showCompetitors])

    useEffect(() => {
        activeGroupRefRenderCount.current++;
    })

    useEffect(() => {
        setPage(1);
        props.activeGroupTabRef.current = activeGroupTab;
        props.activeMoneyTabRef.current = activeMoneyTab;
    }, [props.showExcludedPlans, activeProductTab, activeGroupTab, activeMoneyTab]);

    let origResults = '';
    let results = '';

    try {
        if (props.rawJSONResults) {
            origResults = JSON.parse(props.rawJSONResults);
        }
        results = '';
    } catch (e) {
        console.log(e);
    }

    let problemInterval = useRef(null);
    useEffect(() => {
        if (props.processing) {
            problemInterval.current = setInterval(() => {
                if (origResults == '' && props.processing) {
                    setProblemRunningCase(true);
                    clearInterval(problemInterval.current);
                }
            }, Constants.timeout * 1000)
        }

        props.prevCase.current = props.currentCase.current;

    }, [props.processing]);

    fvsRef.current = props.fvs.filter(e => e != '$0' && e);
    mbsRef.current = props.mbs.filter(e => e != '$0' && e);
    props.fvRefs.current = fvsRef.current;
    props.mbRefs.current = mbsRef.current;

    let multiPriceOption = false;
    let tab = activeMoneyTab;
    if (modeFVRef.current) {
        // we're in face value
        multiPriceOption = (fvsRef.current.length > 1) ? true : false;
    } else {
        multiPriceOption = (mbsRef.current.length > 1) ? true : false;
    }

    if ((JSON.stringify(prevRawJSON.current) != JSON.stringify(origResults))) {
        // is there multi price thing going on?
        modeFVRef.current = props.modeFV; // update the existing mode ref

        // one caveat to knowing this, if modeFV is true and there are budgets but
        // no FVs (like someone set up budgets and switched back to a blank fv)
        // the core defaults to the only valid option 

        let mbCheck = props.mbs.join('').trim();
        let fvCheck = props.fvs.join('').trim();
        if (props.modeFV) {
            if (mbCheck.length >= 1 && fvCheck.length == 0) {
                modeFVRef.current = false;
                props.setModeFV(false);
            }
        } else {
            if (mbCheck.length == 0 && fvCheck.length >= 1) {
                modeFVRef.current = true;
                props.setModeFV(true);
            }
        }

        if (modeFVRef.current) {
            // we're in face value
            multiPriceOption = (fvsRef.current.length > 1) ? true : false;
        } else {
            multiPriceOption = (mbsRef.current.length > 1) ? true : false;
        }

        moneyTabs.current = [];
        setActiveMoneyTab('');
        setActiveGroupTab('');
        setActiveProductTab('');
        setProblemRunningCase(false);
        try {
            clearInterval(problemInterval.current);
        } catch (e) { }

        fvsRef.current = props.fvs.filter(e => e);
        mbsRef.current = props.mbs.filter(e => e);
        props.fvRefs.current = fvsRef.current;
        props.mbRefs.current = mbsRef.current;

        try {
            origResults = JSON.parse(props.rawJSONResults);
            if (!modeFVRef.current) {
                if (mbsRef.current.length > 1) {
                    tab = mbsRef.current[0].replace(/[^\d\.]/g, '');
                    moneyTabs.current = mbsRef.current;
                }
            } else {
                if (fvsRef.current.length > 1) {
                    tab = fvsRef.current[0].replace(/[^\d\.]/g, '');
                    moneyTabs.current = fvsRef.current;
                }
            }


            if (origResults && multiPriceOption) {
                if (tab) {
                    setActiveMoneyTab(tab);
                }
            } else {
                results = origResults[activeMoneyTab];
            }
        } catch (e) { console.log(e) }
        setActiveMoneyTab(tab);

        prevRawJSON.current = origResults;


    }

    useEffect(() => {
        results = origResults[activeMoneyTab];
    }, [activeMoneyTab]);



    if (origResults && multiPriceOption) {
        tab = activeMoneyTab;
        if (tab == '') {
            if (!modeFVRef.current && mbsRef.current.length > 1) {
                tab = mbsRef.current[0].replace(/[^\d\.]/g, '');
                moneyTabs.current = mbsRef.current;
            } else {
                if (modeFVRef.current && fvsRef.current.length > 1) {
                    tab = fvsRef.current[0].replace(/[^\d\.]/g, '');
                    moneyTabs.current = fvsRef.current;
                    results = origResults[tab];
                }
            }
            if (tab) {
                /* the tab used to be set here, but it'll now be set by the ShowTabs component */
                // setActiveMoneyTab(tab);
            }
        } else {
            results = origResults[tab];
        }

    } else {
        results = origResults;
    }

    const updateDB = async (db, carrier, product) => {

        let val = await fetch(`https://compute.bestplanpro.com/logos/?logo=${carrier}&ds=9`, {
            method: 'POST',
            mode: 'cors'
        }).then((out) => out.text());

        db.current[`${carrier}-${product}`] = val;
    }

    const PlanResult = (args) => {

        let data = args.data;
        if (data.results == undefined) {
            alert('Something went wrong.  Please save this case and send it to support.');
            return;
        }
        let [carrier, product, plan] = Data.getCarrierProductPlanFromObject(data);


        let price = data.results.result.replace(/\s*-.*/g, '');
        let displayOutput = data.results.result.replace(/.*Rank \d+\s*/g, '');

        if (data.results['plan-group']) {
            displayOutput = displayOutput.replace(new RegExp(data.results['plan-group'].replace('-', '.'), "i"), '');
        }

        // general replacements for consistency in case we did remove anything
        displayOutput = displayOutput.replace(/  /g, ' ');
        displayOutput = displayOutput.replace(/ :/g, ':');
        displayOutput = displayOutput.replace(/:$/, '');

        if (!props.showExcludedPlans) {
            if (plan.toLowerCase().startsWith('exclude')) {
                return;
            }
        }

        let eappLink = data.results.planinfo.eApp;

        if (!eappLink && data.results.planinfo.hasOwnProperty('epp')) {
            eappLink = data.results.planinfo.eapp;
        }

        let isDiscontinued = false;
        if (data.results.planinfo.hasOwnProperty('*Discontinued')) {
            isDiscontinued = true;
        }

        let [isEnterpriseWithCallback, setIsEnterpriseWithCallback] = useState(Data.isEnterpriseWithCallback());

        let isCompetitor = Data.isCompetitorProduct({ ...args, selectedProducts: props.selectedProducts });

        // @TODO readonly check
        if (props.prevProductStr.current) {
            // likely loaded from compute_case.php

            /* this should be already handled, this is really just to ensure 
             * that competitors are changed appropriately
            */


            var regex = new RegExp("(" + props.prevProductStr.current.replace(/\s/g, '.').replace(/\$/g, ".[a-z\\-\\s\\*0-9]+$").toLowerCase() + ")");
            if (!data.identifier.match(regex)) {
                return;
            } else {
                isCompetitor = false;
            }
        }

        let logoURI = Data.getLogo(carrier, product, props.dataStringLogosDBRef);

        updateDB(props.dataStringLogosDBRef, carrier, product);


        let logoStyle = { width: 98, height: 58, };
        let isMobile = useWindowDimensions().width < 710; /* this is what looks good to me */
        let wrapperStyle = isMobile ? { justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: 5, marginBottom: 5, } : null;
        return (<>
            <Pressable style={[
                isMobile ? Constants.styles.flex : Constants.styles.flexRow,
                isMobile ? { justifyContent: 'center', alignItems: 'center' } : null,
                (isCompetitor && !showCompetitors) ? { display: 'none' } : null,
                {
                    backgroundColor: isCompetitor ? Constants.colors.disabledOption : Constants.colors.paneColor,
                    marginLeft: -1 * Constants.leftOffset,
                    paddingLeft: Constants.leftOffset,
                    paddingBottom: 22,
                    borderBottomWidth: 1,
                    borderColor: Constants.colors.disabledButton,
                    paddingTop: Constants.leftOffset, paddingBottom: Constants.leftOffset, cursor: 'auto'
                },
                { paddingRight: 30 }
            ]}>
                <View style={[wrapperStyle, { marginRight: 34.63, width: 100 }, isMobile ? { marginRight: 0, marginLeft: 0 } : null]} key={`logo-${data.identifier.replace(/\s/g, '-')}`} className="grayscale-image">
                    {logoURI ?
                        (Platform.OS == 'web' ?
                            <img src={logoURI} draggable={false}
                                style={{ ...logoStyle, objectFit: 'contain' }} className={isCompetitor ? "grayscale-image" : null} />
                            :
                            <Image style={logoStyle}
                                resizeMode="contain" className="grayscale-image"
                                source={{ uri: logoURI, cache: 'only-if-cached', }} />
                        )
                        : null
                    }
                </View>
                <View style={[Constants.styles.flex, { width: 269, maxWidth: 269, alignContent: 'center' }]}>
                    <View style={wrapperStyle} key={`name-${data.identifier.replace(/\s/g, '-')}`}>
                        <Text style={styles.resultHeading}>{displayOutput}</Text>
                    </View>
                    <View style={[Constants.styles.flex, wrapperStyle]} key={`price-${data.identifier.replace(/\s/g, '-')}`}>
                        <Text style={styles.price}>{price ? Data.prettyPrice(price) : ''}</Text>
                        <Text style={styles.priceLabel}>{price ?
                            (data.useBudget ? `Death Benefit` : (data.results.product_type == 'preneed' ? `One-time Payment` : `Monthly Price`))
                            : null
                        }</Text>
                    </View>
                    <ResultBadges isOldCase={props.isOldCase} center={isMobile} data={data} prevProductStr={props.prevProductStr} selectedProducts={props.selectedProducts} rank={data.results.rank} carrier={carrier} product={product} isDiscontinued={isDiscontinued} />

                </View>
                <View style={[isMobile ? { marginTop: Constants.leftOffset / 2 } : null, { width: 105 - 40 /* 105 is the distance to the right edge, and we want the last column to be 40 px from the right edge */ }]} key={`indicators-${data.identifier.replace(/\s/g, '-')}`}>

                </View>
                <View style={[Constants.styles.flex, wrapperStyle, { alignItems: 'center', width: 110, justifyContent: 'center' }]}
                    key={`eApp-and-plan-info-launch-${data.identifier.replace(/\s/g, '-')}`}>

                    {isEnterpriseWithCallback ?
                        <Link type="primary-outline" disabled={plan == 'EXCLUDE'} nullButton={1} style={[isMobile ? { width: '70vw' } : null, { textAlign: 'center' }]} onPress={() => {
                            Data.performAPICallback({
                                carrier: carrier,
                                product: product,
                                plan: plan,
                                currentCase: props.currentCase,
                                monthlyPrice: price == 'N/A' ? null : price,
                                link: props.getLink,
                                dataToText: props.dataToText,
                                planInfoId: {
                                    'object': data.results,
                                    'identifier': data.identifier,
                                    'useBudget': props.useBudget,
                                    'amounts': props.useBudget ? mbsRef.current : fvsRef.current,
                                },
                                activeGroupTab: activeGroupTab,
                                activeMoneyTab: activeMoneyTab,
                            })
                        }}>{plan == 'EXCLUDE' ? 'Ineligible' : 'Select'}</Link>
                        : eappLink ?
                            <Link type="primary-outline" style={[isCompetitor ? { borderColor: Constants.colors.primaryColorBG80, color: Constants.colors.primaryColorBG80 } : null, isMobile ? { width: '70vw', textAlign: 'center' } : null]} href={decodeURIComponent(eappLink)}>Access eApp</Link>
                            : <Link type="primary-outline" disabled={true} style={{ width: isMobile ? '70vw' : 108.95, alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} href="#">eApp N/A</Link>}

                    <Pressable
                        onPress={() => {
                            props.setPlanInfoChoice({
                                'object': data.results,
                                'identifier': data.identifier,
                                'useBudget': props.useBudget,
                                'amounts': props.useBudget ? mbsRef.current : fvsRef.current,
                            });
                            props.setShowPlanInfo(true);
                        }}
                        style={[Constants.styles.link, { marginTop: 16, fontSize: Constants.fontSizes.input }, isCompetitor ? { color: Constants.colors.primaryColorBG80 } : null]}><>View Details</></Pressable>
                </View>

            </Pressable >
        </>)
    }

    // get the order based on the data.results.index parameter
    let keys = [];
    let all_keys = [];
    let hasCompetitors = false;
    let allProductTypes = [];

    if ((moneyTabs.current.length == 0 || activeMoneyTab) && results) {
        Object.keys(results).forEach((key) => {

            let args = { 'limit': props.resultLimit, selectedProducts: props.selectedProducts, data: { 'identifier': key, 'results': results[key], 'useBudget': props.useBudget } };

            if (props.prevProductStr.current) {
                // likely loaded from compute_case.php

                var regex = new RegExp("(" + props.prevProductStr.current.replace(/\s/g, '.').replace(/\$/g, ".[a-z\\-\\s\\*0-9]+$").toLowerCase() + ")");
                if (!args.data.identifier.match(regex)) {
                    return false;
                } else {
                    hasCompetitors = false;
                }
            } else {
                if (Data.isCompetitorProduct(args)) {
                    hasCompetitors = true;
                    if (!showCompetitors)
                        return;
                }
            }

            all_keys[results[key].index] = key;


            if (activeProductTab) {
                if (results[key].product_type != activeProductTab) return;
            }

            if (activeGroupTab) {
                if (results[key]['plan-group'] != activeGroupTab) return;
            }


            let shouldShow = true;

            // Apply any filters
            if (props.resultFilters.length > 0) {

                let filters = props.resultFilters;

                let groups = {};
                for (let i = 0; i < filters.length; i++) {
                    let [group, filter] = filters[i].split(/\s*:\s*/);
                    if (!groups.hasOwnProperty(group)) {
                        groups[group] = [{ 'name': filter.toLowerCase(), orig: filterOptions.filter(e => e.value == filters[i])[0] }];
                    } else {
                        groups[group].push({ 'name': filter.toLowerCase(), orig: filterOptions.filter(e => e.value == filters[i])[0] });
                    }

                }

                // go through each filters and make sure that results contain each filter

                for (let i = 0; i < Object.keys(groups).length; i++) {
                    let group = Object.keys(groups)[i];

                    const planinfo = JSON.stringify(results[key].planinfo).toLowerCase();
                    // does 1 filter apply?
                    shouldShow = shouldShow && groups[group].some((filter) => {
                        if (filter.orig.hasOwnProperty('filter')) {
                            return (!!planinfo.match(filter.orig.filter)) == !!filter.orig.filterTestOutcome;
                        } else {
                            return planinfo.includes(filter.name);
                        }
                    });

                }
            }

            if (shouldShow) {
                keys[results[key].index] = key;
            }


        })
    }

    // get non-excluded plans
    let nonExcluded = [];
    let all_nonExcluded = [];
    if (results) {
        Object.keys(results).forEach((key) => {
            let args = { 'limit': props.resultLimit, selectedProducts: props.selectedProducts, data: { 'identifier': key, 'results': results[key], 'useBudget': props.useBudget } };

            // @TODO readonly check
            if (props.prevProductStr.current) {
                // likely loaded from compute_case.php

                var regex = new RegExp("(" + props.prevProductStr.current.replace(/\s/g, '.').replace(/\$/g, ".[a-z\\-\\s\\*0-9]+$").toLowerCase() + ")");
                if (!args.data.identifier.match(regex)) {
                    return false;
                } else {
                    hasCompetitors = false;
                }
            } else {
                if (Data.isCompetitorProduct(args)) {
                    hasCompetitors = true;
                    if (!showCompetitors)
                        return;
                }
            }


            if ((results[key].rank + '') == Constants.excludedRank) return;


            if (activeProductTab) {
                if (results[key].product_type != activeProductTab) return;
            }

            if (activeGroupTab) {
                if (results[key]['plan-group'] != activeGroupTab) return;
            }





            let shouldShow = true;

            // Apply any filters
            if (props.resultFilters.length > 0) {

                let filters = props.resultFilters;

                let groups = {};
                for (let i = 0; i < filters.length; i++) {
                    let [group, filter] = filters[i].split(/\s*:\s*/);
                    if (!groups.hasOwnProperty(group)) {
                        groups[group] = [{ 'name': filter.toLowerCase(), orig: filterOptions.filter(e => e.value == filters[i])[0] }];
                    } else {
                        groups[group].push({ 'name': filter.toLowerCase(), orig: filterOptions.filter(e => e.value == filters[i])[0] });
                    }

                }

                // go through each filters and make sure that results contain each filter

                for (let i = 0; i < Object.keys(groups).length; i++) {
                    let group = Object.keys(groups)[i];

                    const planinfo = JSON.stringify(results[key].planinfo).toLowerCase();
                    // does 1 filter apply?
                    shouldShow = shouldShow && groups[group].some((filter) => {
                        if (filter.orig.hasOwnProperty('filter')) {
                            return (!!planinfo.match(filter.orig.filter)) == !!filter.orig.filterTestOutcome;
                        } else {
                            return planinfo.includes(filter.name);
                        }
                    });

                }
            }

            if (shouldShow) {
                all_nonExcluded.push(key);
                nonExcluded[results[key].index] = key;

            }


        })
    }

    let productTypes = [];
    let productGroups = {};

    let allProductGroups = {};
    let examine = all_keys;
    if (!props.showExcludedPlans) {
        examine = all_nonExcluded;
        resultKeys.current = (nonExcluded.filter(e => e));
    } else {
        resultKeys.current = (keys.filter(e => e));
    }

    examine = examine.filter(e => e);

    all_keys.forEach((key) => {
        if (!productTypes.includes(results[key].product_type)) {
            productTypes.push(results[key].product_type);
        }

        if (results[key].hasOwnProperty('plan-group')) {
            if (!productGroups.hasOwnProperty(results[key].product_type)) {
                productGroups[results[key].product_type] = [];
            }

            productGroups[results[key].product_type].push(results[key]['plan-group']);
        }
    });

    let all_product_types = [];
    try {
        if (props.isReadonly.current) {
            all_product_types = JSON.stringify(origResults).match(/"product_type":"(\w+)"/g);
            all_product_types = [...new Set(all_product_types.map((x) => {
                return x = x.replace(/['"]/g, '').replace('product_type:', '');
            }))];


        }
    } catch (e) { }


    /*
    alert(`Zach, the problem is that product types is blank when loading in case data from string  need to determine product types...then
    we can get the planInfoOptionsRef full and then plan info will work – if you click on VIEW DETAILS it wont work because the array is blank b/c of this.
    `)
    */

    let planInfoOptions = [];

    examine.forEach(function (key) {
        if (results[key].product_type == activeProductTab && (!activeGroupTab || activeGroupTab == results[key]['plan-group'])) {
            planInfoOptions[results[key].index] = { 'results': results[key], 'identifier': key };
        }
    });
    planInfoOptions.filter(e => e);

    props.planInfoOptionsRef.current = planInfoOptions;

    // Set the product types (for the first time)
    if (origResults /*&& !props.prevProductStr.current*/) {

        let selectedProducts = props.selectedProducts;

        if (!props.isReadonly.current) {
            selectedProducts = Data.filterProductsToOnlyChecked(props.selectedProducts);
        }

        let selectedProductTypes = [];
        if (Object.keys(selectedProducts).length > 0) {
            selectedProductTypes = Object.keys(selectedProducts).map((x) => { return { 'label': Data.getPrettyProductType(x), 'value': x } });
        }
        if (!props.isReadonly.current) {
            productTypes = selectedProductTypes.map((x) => x.value);
            productTypes = Array.from(new Set(productTypes));
        }


    }


    // this shouldn't be necessary since product types we unchecked shouldn't be run
    if (!props.isReadonly.current) {
        if (!props.showMedsup) productTypes = productTypes.filter(e => e != 'medsup');
        if (!props.showTerm) productTypes = productTypes.filter(e => e != 'term');
        if (!props.showFEX) productTypes = productTypes.filter(e => e != 'fex');
        if (!props.showPreneed) productTypes = productTypes.filter(e => e != 'preneed');
    }

    if (props.isReadonly.current) {
        productTypes = [...new Set(productTypes.concat(all_product_types))];
    }

    productTypes.sort();

    let pages = [...Array(Math.ceil((resultKeys.current.length) / props.resultLimit)).keys()];
    pages = pages.map((e) => e + 1) // base 1, not base zero

    let prevDots = false;
    let count = 0;
    let [forceHideOutdated, setForceHideOutdated] = useState(false);

    let shownPlanOptions = useRef(0);
    let testedPlanOptions = useRef(0);

    const [filterRulesOpen, setFilterRulesOpen] = useState(false);

    if (page > pages.length) { setPage(pages.length) }

    return (<>
        <OutdatedWarning {...props} isReadonly={props.isReadonly} setForceHideOutdated={setForceHideOutdated} forceHideOutdated={forceHideOutdated}
            key={`outdated-warning-key-${activeGroupRefRenderCount.current}-${props.currentCaseRenderCount}`}
            activeGroupRefRenderCount={activeGroupRefRenderCount} prevCase={props.prevCase} currentCase={props.currentCase}
            processing={props.processing} setTriggerFunction={props.setTriggerFunction} />
        <Pane id="results" name="results" hideOverflow={1} heading="Top Recommendations" headingStyle={props.isMobile ? { flexWrap: 'wrap', gap: 15 } : null} secondary={!props.processing ?
            <>
                <View style={[props.isMobile ? Constants.styles.flexCol : Constants.styles.flexRow, props.isMobile ? { gap: 15 } : null, Constants.styles.verticalCenter]}>

                    <FilterField options={filterOptions} key={`filter-${filterRefresh.current}`}
                        open={filterRulesOpen} setOpen={setFilterRulesOpen}
                        getter={props.resultFilters} setter={props.setResultFilters}
                        blankText={props.resultFilters.length == 0 ? "Filter options" : `Filter options (${props.resultFilters.length})`} width={props.isMobile ? '70vw' : 232}
                        onChange={() => {
                            setPage(1);
                            refreshCount.current++;
                        }}
                        forceClose={forceCloseFilters} setForceClose={setForceCloseFilters}
                        dropdownStyle={[{ top: 47, left: 0 }, props.isMobile ? { right: 0, marginTop: 0, top: 47, minWidth: 'min(95vw, 232px)', left: 0 } : { minWidth: 'min(95vw, 232px)', left: 0 }]}
                    ></FilterField>

                </View>
            </> : null}
            footerStyle={{ marginTop: 0, borderTopWidth: 0, paddingLeft: Constants.leftOffset, paddingRight: Constants.leftOffset, paddingBottom: Constants.leftOffset, paddingTop: Constants.leftOffset, }}
            footer={
                <View style={[Constants.styles.flex, { width: '100%' }]} key={'refresh-main-pane-' + refreshCount.current}>
                    <View style={{ alignContent: 'center', marginBottom: 20 }}>
                        {pages.length > 0 ?
                            <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { justifyContent: 'center', width: '100%' }]}>
                                <Pressable style={{ marginRight: 10, marginLeft: 10 }} onPress={() => { if (page > 1) setPage(page - 1) }}>
                                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.721637 5.65865C0.428725 5.95156 0.428725 6.42725 0.721637 6.72016L5.22076 11.2193C5.51367 11.5122 5.98936 11.5122 6.28227 11.2193C6.57518 10.9264 6.57518 10.4507 6.28227 10.1578L2.31273 6.18823L6.27993 2.2187C6.57284 1.92578 6.57284 1.4501 6.27993 1.15718C5.98701 0.864272 5.51133 0.864272 5.21841 1.15718L0.719293 5.65631L0.721637 5.65865Z"
                                            fill={(page == 1 ? "#DDDDDD" : "#999999")} />
                                    </svg>
                                </Pressable>
                                {pages.map((pageNo) => {

                                    /* only show 5 pages
                                        1) Show the first
                                        2) Show the last
                                        3) Show 1 page on either side, or 2 on one side
        
                                    */

                                    let showPages = 5;
                                    let show_page = false;
                                    let buffer = showPages - 2 - 2; // 2 pages for first and last and 2 for buffer on each side

                                    if (pageNo == 1
                                        || pageNo == pages.length
                                        || pageNo == page
                                        || pageNo <= page + (buffer) && pageNo >= page - buffer
                                        || ((page == 1) && pageNo <= (1 + buffer))
                                        || ((page == pages.length) && pageNo >= pages.length - (1 + buffer))
                                    ) {
                                        show_page = true;
                                    }

                                    if (show_page) {
                                        prevDots = false;
                                        return <>
                                            <Pressable style={[page == pageNo ? styles.activePage : styles.inactivePage, { marginRight: 10, marginLeft: 10 }]} onPress={() => { setPage(pageNo); Data.scroll_into_view('results'); }}><Text selectable={false} style={page == pageNo ? { color: 'white' } : null}>{pageNo}</Text></Pressable>
                                        </>
                                    } else {
                                        if (prevDots) return;
                                        prevDots = true;
                                        return <>
                                            <Pressable style={[styles.inactivePage, { marginRight: 10, marginLeft: 10 }]} ><Text selectable={false} style={page == pageNo ? { color: 'white' } : null}>...</Text></Pressable>
                                        </>
                                    }
                                })}
                                <Pressable style={{ marginLeft: 10, marginRight: 10 }} onPress={() => { if (page < pages.length) setPage(page + 1) }}>
                                    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.28227 5.65865C6.57518 5.95156 6.57518 6.42725 6.28227 6.72016L1.78315 11.2193C1.49024 11.5122 1.01455 11.5122 0.721637 11.2193C0.428726 10.9264 0.428726 10.4507 0.721637 10.1578L4.69117 6.18823L0.72398 2.2187C0.431068 1.92578 0.431068 1.4501 0.72398 1.15718C1.01689 0.864272 1.49258 0.864272 1.78549 1.15718L6.28461 5.65631L6.28227 5.65865Z"
                                            fill={(page == pages.length ? "#DDDDDD" : "#999999")} />
                                    </svg>
                                </Pressable>

                            </View>
                            : null}
                    </View>
                    {props.processing ? <></> :
                        (props.isMobile ?
                            <>
                                <View style={[Constants.styles.flexRow, { flexWrap: 'wrap', gap: 10, marginTop: 15 }]}>
                                    <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { marginRight: 20, flexGrow: 1, marginBottom: 10 }]}>
                                        <Text selectable={false} style={[Constants.styles.label, { marginRight: 10, width: 156 }]}>Show Ineligible Options</Text>
                                        <BooleanSwitch get={props.showExcludedPlans} set={props.setShowExcludedPlans} savePreference="showExcludedPlans" onChange={() => { Data.scroll_into_view('results'); setPage(1); }}></BooleanSwitch>

                                    </View>

                                    <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { marginRight: 20, flexGrow: 1 }, (!(props.fexCompetitiveAnalysisMode && activeProductTab == 'fex')) ? { display: 'none' } : null]}>
                                        <Text selectable={false} style={[Constants.styles.label, { marginRight: 10, width: 156 }]}>Show Competitors</Text>
                                        <BooleanSwitch get={showCompetitors} set={setShowCompetitors} savePreference="showCompetitors" onChange={() => { Data.scroll_into_view('results'); setPage(1); }}></BooleanSwitch>

                                    </View>
                                    <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}><Text selectable={false} style={{ marginRight: 16 }}>Results per page</Text><SelectField
                                        value={props.resultLimit}
                                        style={{ maxWidth: 60, width: 60 }}
                                        options={Constants.resultsPerPageOptionSet}
                                        getter={props.resultLimit}
                                        setter={props.setResultLimit}
                                        wrapperStyle={{ maxWidth: 82 /* includes 20px of padding */ }}
                                        maxWidth={82}
                                        onValueChange={(val) => {
                                            props.setResultLimit(val);
                                            Data.savePreference("resultLimit", val);
                                            setPage(1);
                                            Data.scroll_into_view('results');
                                        }}
                                    >

                                    </SelectField>
                                    </View>
                                </View>
                            </>
                            :
                            <>
                                <View style={[Constants.styles.flexRow, { flexWrap: 'wrap' }]}>
                                    <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { marginRight: 20, flexGrow: 1, marginBottom: 10 }]}>
                                        <Text selectable={false} style={[Constants.styles.label, { marginRight: 10, width: 156 }]}>Show Ineligible Options</Text>
                                        <BooleanSwitch get={props.showExcludedPlans} set={props.setShowExcludedPlans} savePreference="showExcludedPlans" onChange={() => { Data.scroll_into_view('results'); setPage(1); }}></BooleanSwitch>

                                    </View>
                                    <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter]}><Text selectable={false} style={{ marginRight: 16 }}>Results per page</Text><SelectField
                                        value={props.resultLimit}
                                        style={{ maxWidth: 60, width: 60 }}
                                        options={Constants.resultsPerPageOptionSet}
                                        getter={props.resultLimit}
                                        setter={props.setResultLimit}
                                        wrapperStyle={{ maxWidth: 82 /* includes 20px of padding */ }}
                                        maxWidth={82}
                                        onValueChange={(val) => {
                                            props.setResultLimit(val);
                                            Data.savePreference("resultLimit", val);
                                            setPage(1);
                                            Data.scroll_into_view('results');
                                        }}
                                    >

                                    </SelectField>
                                    </View>
                                </View>

                                <View style={[Constants.styles.flexRow, Constants.styles.verticalCenter, { marginRight: 20, flexGrow: 1 }, (!(props.fexCompetitiveAnalysisMode && activeProductTab == 'fex')) ? { display: 'none' } : null]}>
                                    <Text selectable={false} style={[Constants.styles.label, { marginRight: 10, width: 156 }]}>Show Competitors</Text>
                                    <BooleanSwitch get={showCompetitors} set={setShowCompetitors} savePreference="showCompetitors" onChange={() => { Data.scroll_into_view('results'); setPage(1); }}></BooleanSwitch>

                                </View>
                            </>
                        )}

                </View>
            }

        >

            <KeyboardShortcut moddedShortcut='ArrowRight' callback={() => {
                if (page < pages.length) setPage(page + 1);
            }} />
            <KeyboardShortcut moddedShortcut='ArrowLeft' callback={() => {
                if (page > 1) setPage(page - 1);
            }} />

            <ShowTabs
                moneyOptionLabel={modeFVRef.current ? 'Face Value' : 'Monthly Payment'}
                moneyOptions={moneyTabs.current}
                setActiveMoneyTab={setActiveMoneyTab} activeMoneyTab={activeMoneyTab}
                activeProductTab={activeProductTab} setActiveProductTab={setActiveProductTab}
                activeGroupTab={activeGroupTab} setActiveGroupTab={setActiveGroupTab}
                productTypes={productTypes} fvs={fvsRef.current} mbs={mbsRef.current}
                productGroups={productGroups.hasOwnProperty(activeProductTab) ? productGroups[activeProductTab] : []} />

            <View style={{ minHeight: props.processing ? 200 : 400 }} key={JSON.stringify(props.resultFilters)}>
                {
                    resultKeys.current.map((x, idx) => {

                        let isExcluded = (results[x].rank + '') == Constants.excludedRank;
                        let index = idx + 1; // base 1, not base zero

                        let shouldShow = true;

                        // if on page 1, if the index is > than the limit, we're on page 2+

                        if (page == 1 && index > props.resultLimit) {
                            shouldShow = false;
                        }

                        // if on page 1, if the index is > than the limit, the result should be on the subsequent page
                        if (page > 1 && index > (props.resultLimit * page)) {
                            shouldShow = false;
                        }

                        // don't show less than limit, but appropriate to the page
                        // page 3 shows index 20-30

                        if (page >= 2 && index <= (props.resultLimit * (page - 1))) {
                            shouldShow = false;
                        }

                        testedPlanOptions.current++;

                        if (shouldShow) {
                            shownPlanOptions.current++;
                            return <PlanResult limit={props.resultLimit} page={page} data={{ 'identifier': x, 'results': results[x], 'useBudget': props.useBudget, }} ></PlanResult>;
                        }
                    })
                }

                {
                    props.processing ?
                        <View style={[{ alignContent: 'center', paddingTop: Constants.leftOffset, paddingBottom: Constants.leftOffset }, Constants.styles.verticalCenter]}>
                            {!problemRunningCase ?
                                <>
                                    <Text selectable={false} style={{ fontWeight: '500', marginBottom: 20 }}>
                                        Running quote.
                                    </Text>
                                    <ActivityIndicator animating={props.processing} size="large" color={Constants.colors.primaryColorBG} />
                                    {SAHProgressBar({ progress: progress, style: { minWidth: "75%", marginTop: "15px" }, completeCriteria: !props.processing })}
                                    <Text selectable={false} style={{ fontWeight: '500', marginTop: 20 }}>This may take up to {Constants.timeout} seconds.</Text>
                                    {0 ? (<><Text selectable={false} style={{ fontWeight: '500', marginTop: 20 }}> </Text>
                                        <Text selectable={false} style={{ fontWeight: '500', marginTop: 20 }}> </Text>

                                        <Text selectable={false} style={{ display: 'none', fontWeight: '500', marginTop: 20, fontStyle: 'italic' }}>Running 300+ million computation and processing</Text>
                                        <Text selectable={false} style={{ display: 'none', fontWeight: '500', marginTop: 0, fontStyle: 'italic' }}>rules to ensure accuracy for you and your client.</Text>
                                    </>) : <></>}
                                </>
                                :
                                <>{Data.scroll_into_view('results')}
                                    <Text selectable={false} style={{ fontWeight: '500' }}>Something went wrong.  Please <Pressable onPress={() => { props.setTriggerFunction('saveDataWithPrefs'); }} style={Constants.styles.link}>Save Your Case</Pressable> and alert support.</Text>
                                    <Text selectable={false} style={{ fontWeight: '500' }}>If you thing the issue is resolved, you can also <Pressable onPress={() => { props.setTriggerFunction('analyze'); }} style={Constants.styles.link}>retry your quote</Pressable>.</Text>
                                </>
                            }
                        </View>

                        : null

                }

                {((resultKeys.current.length == 0 && all_keys.length > 0) ?
                    <View style={[{ alignContent: 'center', paddingTop: Constants.leftOffset, paddingBottom: Constants.leftOffset }, Constants.styles.verticalCenter]}>
                        <Text selectable={false} style={{ fontWeight: '500', marginBottom: 20 }}>Client is ineligible for all of the requested {`${activeProductTab != 'medsup' ? (activeMoneyTab ? Data.prettyPrice(activeMoneyTab).replace('.00', '') : '') : ''} ${activeProductTab} ${activeGroupTab}`.replace('  ', ' ').trim()} options.</Text><Text selectable={false} style={{ fontWeight: '500' }}>You may be able to see the reason for ineligibility by</Text><Text selectable={false} style={{ fontWeight: '500' }}>viewing ineligible options (use the switch below) and clicking on View Details.</Text>
                    </View>

                    : (shownPlanOptions.current == 0 ?
                        <View style={[{ alignContent: 'center', paddingTop: Constants.leftOffset, paddingBottom: Constants.leftOffset }, Constants.styles.verticalCenter]}>
                            <Text selectable={false} style={{ fontWeight: '500', marginBottom: 20 }}></Text>
                        </View>
                        : null))

                }

            </View>

        </Pane >
    </>);

}

export default Results

const styles = new StyleSheet.create({
    bkgBird: {
        height: 512 * .65,
        width: 512 * .65,
        position: 'absolute',
        zIndex: -5000,
        margin: 0,
        padding: 0,
        right: -105,
        top: -90,
        opacity: .2,

    },
    activePage: {
        borderRadius: 200,
        backgroundColor: Constants.colors.primaryButton,
        color: 'white',
        width: 20.5,
        height: 20.5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 4,
        paddingBottom: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    inactivePage: {
        borderRadius: 200,
        backgroundColor: Constants.colors.paneColor,
        width: 20.5,
        height: 20.5,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 4,
        paddingBottom: 4,
        alignItems: 'center',
        justifyContent: 'center',
    },
    resultHeading: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.menuHeading,
        marginBottom: 17,
    },
    priceLabel: {
        fontWeight: '500',
        fontSize: Constants.fontSizes.smallerLabel,
        marginBottom: 22,
    },
    price: {
        fontWeight: '700',
        fontSize: Constants.fontSizes.menuHeading,
        marginBottom: 0,
    },
    badgeSpacing: {
        marginRight: 16.68,
    }
});

// src/ExpandingTextField.js

import React, { useState, useRef, useEffect } from 'react'
import { View, StyleSheet, Pressable, Button } from 'react-native'
import Pane from './Pane';
import Row from './Row';
import * as Constants from '../theme'
import TextInputField, { SelectField, RadioField, SwitchButton, BooleanSwitch } from './Input';
import { Select, CheckIcon, ChevronDownIcon, Radio, Box, Icon, IconButton } from "native-base";
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import Text from './Text';

function ExpandingTextField(props) {

    let [focusIndex, setFocusIndex] = useState(0);
    let [mbCount, setMBCount] = useState(1);
    let [mbFocusIndex, setMBFocusIndex] = useState(0);
    let refs = useRef([]);
    let [getter, setter] = useState([...props.getter]);

    useEffect(() => {
        props.fixer(getter, setter)
        // props.setter(getter);
        if (focusIndex != 0) {
            refs.current[focusIndex].focus();
        }
    }, [focusIndex,]);

    useEffect(() => {
        //   props.setter(getter);
    }, [getter])

    let forcedRefresh = useRef(0);

    let index = -1; // incrementing early
    let plusPressed = useRef(false);

    return (
        [...Array(getter.length).keys()].reverse().map(function (id) {
            index++;

            let top_of_list = getter.length - 1; // there's always 1 blank
            let bottom_of_list = 0;

            return (
                <>
                    <Row bottomSpacing={id != bottom_of_list ? (getter.length > 1 ? Constants.rowMarginBottom * 0.5 : Constants.rowMarginBottom) : Constants.rowMarginBottom} key={`${props.idPrefix}-${id}-row`} label={id == top_of_list ? props.fieldTitle : ''} labelIconTooltip={props.labelIconTooltip}
                        labelIcon={id == top_of_list ? (props.labelIconTooltip ?
                            <IconButton icon={<Icon as={FontAwesome} name="question-circle-o" color={Constants.colors.label}
                                style={[styles.moreInfoIcon, { marginLeft: 0 }]} />} />
                            : '') : ''}
                    >
                        <View key={`${props.idPrefix}-${id}-view`} style={[{ flexDirection: 'row', alignItems: 'center' }]}>
                            <TextInputField key={`${props.idPrefix}-${id}-${forcedRefresh.current}`} style={[Constants.styles.textControl, { width: Constants.controlMaxWidth + 2 }]} refVal={(elem) => { refs['current'][id] = elem; }}
                                autoFocus={plusPressed.current && id == top_of_list}
                                onKeyPress={(e) => {
                                    if (props.readonly) return e.preventDefault();
                                    if (['/', '-', '|', '\\', 'Enter'].includes(e.key)) {
                                        let x = [...getter];
                                        if (e.key == 'Enter') {
                                            if (x[id] == "") {
                                                return;
                                            }
                                        }
                                        x = x.map(e => e.replace(/[\/\-\|\\]/g, ''));
                                        if (x[id + 1] != '') {
                                            x.splice(id + 1, 0, "");
                                        }
                                        setter(x);
                                        setFocusIndex(id + 1)
                                        plusPressed.current = true;
                                        e.preventDefault();
                                    }
                                }}
                                onBlur={(e) => {
                                    let contents = getter;
                                    if (Array.isArray(contents) && contents.length == 1) {
                                        contents = contents[0].split(/[\|\\\/\-]/);
                                    }
                                    let x = props.fixer(contents, setter);
                                    e.target.value = x[id];
                                    setter(x);
                                    props.setter(x);
                                }}
                                onKeyDown={(e) => {
                                    if (props.readonly) return e.preventDefault();
                                }}
                                id={`${props.idPrefix}-${id}`}
                                startAdornment="$"
                                editable={!props.readonly}
                                adornmentClass={Constants.styles.textControlCaption}
                                value={getter[id]}
                                placeholder={getter[id]}
                                getterSetterType="array"
                                onChangeText={(val) => { let x = [...getter]; x[id] = val; setter(x); }}
                                keyboardType={props.keyboardType}
                                isExpanding={1}
                            >

                            </TextInputField>


                            <Pressable disabled={props.readonly} onMouseUp={() => { let x = [...getter].filter((val, idx) => idx != id); if (x.length == 0) x = ['']; setter(x); props.setter(x); forcedRefresh.current++; }} onPress={() => { let x = [...getter].filter((val, idx) => idx != id); if (x.length == 0) x = ['']; setter(x); forcedRefresh.current++; }}>
                                <Icon key={`${props.idPrefix}-${id}-minus`} as={AntDesign} name="minuscircle" color={Constants.colors.radioBorder} style={[styles.moreInfoIcon, { marginLeft: 14 }]} />
                            </Pressable>
                            {(id == top_of_list) ? <Pressable disabled={props.readonly} onMouseUp={() => { setter([...getter, '']); plusPressed.current = true; }} onPress={() => { setter([...getter, '']); plusPressed.current = true; }}>
                                <Icon key={`${props.idPrefix}-${id}-plus`} as={AntDesign} name="pluscircle" color={Constants.colors.primaryButton} style={[styles.moreInfoIcon, { marginLeft: 14 }]} />
                            </Pressable> : null
                            }

                        </View>
                    </Row >
                </>
            )
        }));
}

export default ExpandingTextField

const styles = new StyleSheet.create({
    header: {
        fontWeight: '700',
        paddingBottom: 15,
        fontSize: Constants.fontSizes.headerText,
        lineHeight: 18,
        paddingTop: 23,
        paddingBottom: 23,
        paddingLeft: Constants.leftOffset,
        borderBottomWidth: 1,
        borderColor: Constants.colors.gray,
        marginBottom: 55,
        boxSizing: 'border-box',
    },
    heading: {
        fontWeight: '700',
        fontSize: Constants.fontSizes.headerText,
    },
    card: {
        marginBottom: 0,
        justifyContent: 'space-between',
        flexDirection: 'column',
        color: Constants.colors.label,
        backgroundColor: Constants.colors.ExpandingTextFieldColor,
    },
    cardBody: {
        paddingLeft: Constants.leftOffset,
    },
    secondaryOption: {
        alignContent: 'right',
        paddingRight: Constants.leftOffset,
        fontSize: Constants.fontSizes.input,
        fontWeight: '600',
    },
    hidden: {
        display: 'none',
    },
    flex: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    }
});

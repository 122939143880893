// src/Pane.js

import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Icon } from 'native-base';
import Text from './Text';

function Pane({ style, bodyStyle, secondaryNoPress, noHeaderBorder, hideOverflow, shouldToggleBeOpen, whichToggleOpenLocal, setWhichToggleOpenLocalLocal, whichToggleOpen, togglePane, onLayout, id, heading, footer, bottomSpacing, headingStyle, footerStyle, secondary, children, secondaryIcon, secondaryStyle, secondaryWrapStyle, secondaryPress, overrideStyle }) {

    let paneSpacing = 53;

    let styles = new StyleSheet.create({
        header: {
            fontWeight: '700',
            paddingBottom: 15 * Constants.zoomFactor,
            fontSize: Constants.fontSizes.headerText,
            lineHeight: 18,
            paddingTop: 23 * Constants.zoomFactor,
            paddingBottom: 23 * Constants.zoomFactor,
            paddingLeft: Constants.leftOffset,
            borderBottomWidth: noHeaderBorder ? 0 : 1,
            borderColor: Constants.colors.gray,
            marginBottom: bottomSpacing !== undefined ? bottomSpacing * Constants.zoomFactor : paneSpacing * Constants.zoomFactor,
            boxSizing: 'border-box',
            alignContent: 'center',
            alignItems: 'center',
            zIndex: 5,
        },
        footer: {
            fontWeight: '700',
            fontSize: Constants.fontSizes.headerText,
            lineHeight: 18 * Constants.zoomFactor,
            borderTopWidth: 1,
            borderColor: Constants.colors.gray,
            boxSizing: 'border-box',
            alignContent: 'center',
            alignItems: 'flex-end',
            display: 'flex',
            marginTop: paneSpacing * Constants.zoomFactor,
        },
        toggleHeaderOpen: {
            paddingTop: 17.5 * Constants.zoomFactor,
            paddingBottom: 9 * Constants.zoomFactor,
            borderBottomWidth: 0,
        },
        toggleHeaderClosed: {
            paddingTop: 9 * Constants.zoomFactor,
            paddingBottom: 9 * Constants.zoomFactor,
        },
        toggleHeader: {
            lineHeight: 16 * Constants.zoomFactor,
            marginLeft: 19 * Constants.zoomFactor,
            fontSize: Constants.fontSizes.label,
            marginBottom: isTogglePaneOpen ? paneSpacing * Constants.zoomFactor : 0,
        },
        togglePane: {
            marginRight: Constants.leftOffset * Constants.zoomFactor,
            borderRadius: Constants.borderRadius,
            marginBottom: isTogglePaneOpen ? paneSpacing * Constants.zoomFactor : 0,
        },
        heading: {
            fontWeight: '700',
            fontSize: Constants.fontSizes.headerText,
            color: Constants.colors.textColor,
        },
        togglePaneHeading: {
            color: Constants.colors.primaryButton,
            textTransform: 'uppercase',
            fontWeight: '500',
            fontSize: Constants.fontSizes.label,
            color: Constants.colors.textColor,
        },
        card: {
            marginBottom: 0,
            justifyContent: 'space-between',
            flexDirection: 'column',
            color: Constants.colors.label,
            //backgroundColor: togglePane ? Constants.colors.togglePane : Constants.colors.paneColor,
            backgroundColor: Constants.colors.paneColor,
            borderRadius: Constants.borderRadius,
            borderColor: togglePane ? Constants.colors.primaryColorBG60 : null,
            borderWidth: togglePane ? 1 : 0,
            borderStyle: togglePane ? 'dashed' : null,
            overflow: hideOverflow ? 'hidden' : 'visible',
        },
        cardBody: {
            paddingLeft: Constants.leftOffset,

        },
        toggleCardBody: {
            marginTop: paneSpacing,
            marginBottom: paneSpacing,
        },
        secondaryOption: {
            alignContent: 'right',
            paddingRight: Constants.leftOffset * Constants.zoomFactor,
            fontSize: Constants.fontSizes.input,
            fontWeight: '600',
        },
        hidden: {
            display: 'none',
        },
        flex: {
            flexDirection: 'row',
            justifyContent: 'space-between',
        }
    });

    const [isTogglePaneOpen, setIsTogglePaneOpen] = useState(shouldToggleBeOpen ? shouldToggleBeOpen : false);
    const [showChildren, setShowChildren] = useState(whichToggleOpenLocal == whichToggleOpen);

    useEffect(() => {
        if (togglePane) {
            if (isTogglePaneOpen) {
                if (showChildren == false) {
                    setShowChildren(true);
                }
                if (setWhichToggleOpenLocalLocal) {
                    setWhichToggleOpenLocalLocal(whichToggleOpen);
                }
            } else {
                if (showChildren == true) {
                    setShowChildren(false);
                }
            }
        } else {
            if (showChildren == false) {
                setShowChildren(true);
            }
        }

    }, [togglePane, isTogglePaneOpen]);

    useEffect(() => {
        if (whichToggleOpenLocal == whichToggleOpen) {
            setIsTogglePaneOpen(true);
        } else {
            setIsTogglePaneOpen(false);
        }
    }, [whichToggleOpenLocal]);

    const Heading = () => {
        if (togglePane) {
            return (
                <Pressable styles={[styles.togglePane, isTogglePaneOpen ? styles.toggleHeaderOpen : styles.toggleHeaderClosed]} onPress={() => { setIsTogglePaneOpen(!isTogglePaneOpen) }}>
                    <View style={[styles.flex, styles.header, styles.toggleHeader, isTogglePaneOpen ? styles.toggleHeaderOpen : styles.toggleHeaderClosed, headingStyle]}>
                        <Text style={[styles.heading, styles.togglePaneHeading, isTogglePaneOpen ? styles.toggleHeaderOpen : styles.toggleHeaderClosed, headingStyle]}>{heading}</Text>
                        <Pressable onPress={() => { setIsTogglePaneOpen(!isTogglePaneOpen); secondaryPress }} style={[{ flexDirection: 'row', alignItems: 'center', justifyContent: 'right', marginRight: 20 }, secondaryWrapStyle]}>
                            <Icon name={isTogglePaneOpen ? 'up' : 'down'} as={AntDesign} />
                        </Pressable>
                    </View >
                </Pressable>

            );
        }

        return secondaryNoPress ? (
            <><View style={[styles.flex, styles.header, headingStyle]}>
                <Text style={[styles.heading, headingStyle]}>{heading}</Text>
                <View style={[{ flexDirection: 'row', alignItems: 'right', justifyContent: 'right', alignItems: 'center', cursor: 'pointer' }, secondaryWrapStyle]}>
                    {secondaryIcon ? secondaryIcon : null}
                    {secondary ? <Text style={secondaryStyle ? secondaryStyle : styles.secondaryOption}>{secondary}</Text> : null}
                </View>
            </View>
            </>
        ) :
            (<><View style={[styles.flex, styles.header, headingStyle]}>
                <Text style={[styles.heading, headingStyle]}>{heading}</Text>
                <Pressable onKeyUp={(e) => {
                    if (e.code == 'Space') {
                        secondaryPress();
                    }
                }}
                    onPress={secondaryPress} style={[{ flexDirection: 'row', alignItems: 'right', justifyContent: 'right', alignItems: 'center' }, secondaryWrapStyle]}>
                    {secondaryIcon ? secondaryIcon : null}
                    {secondary ? <Text style={secondaryStyle ? secondaryStyle : styles.secondaryOption}>{secondary}</Text> : null}
                </Pressable>

            </View>
            </>
            );
    }

    const Footer = () => {

        if (!footer) return;

        return (
            <View style={[styles.flex, styles.footer, footerStyle]}>
                {footer}
            </View>
        );
    }

    if (!footer) {
        return (
            <View onLayout={onLayout} style={[styles.card, (togglePane ? styles.togglePane : null), style]} nativeID={id}>
                <Heading />
                {showChildren ?
                    <View style={[styles.cardBody, togglePane ? styles.toggleCardBody : null, bodyStyle]}>
                        {children}
                    </View>
                    : null}
                <Footer />
            </View>);
    } else {
        return (
            <View onLayout={onLayout} style={[styles.card, (togglePane ? styles.togglePane : null), style]} nativeID={id}>
                <Heading />
                {showChildren ?
                    <View style={[styles.cardBody, togglePane ? styles.toggleCardBody : null, bodyStyle]}>
                        {children}
                    </View>
                    : null}
                <Footer />
            </View>);
    }
}

export default Pane


// src/MenuOption.js

import React, { useEffect, useState, } from 'react'
import { View, StyleSheet, Pressable, Modal, Platform, Linking } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Icon } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField from './Input'
import Link from './Link';
import Text from './Text';

function MenuOption(props) {

    let shortcut = '';
    if (props.moddedShortcut) {
        if (Platform.OS === 'web') {
            shortcut = 'Ctrl + ' + props.moddedShortcut.toUpperCase();
        } else {
            shortcut = '';
        }
    }


    let levelOffset = Constants.leftOffset * (props.level ? ((props.level > 1 ? (((props.level - 1) * .7) + 1) : 1)) : 1)
    const styles = StyleSheet.create({
        mobile: {
            fontSize: Constants.fontSizes.input,
            borderTopWidth: 1,
            borderBottomWidth: props.lastOption ? 1 : 0,
            borderColor: Constants.colors.gray,
            paddingLeft: levelOffset,
            paddingRight: levelOffset,
            marginBottom: 0,
            paddingTop: Constants.leftOffset,
        }
    })

    return (<>
        <Pressable onPress={(e) => {
            if (props.onPress) props.onPress(e);
        }}
            style={[Constants.styles.link, { marginBottom: props.lastOption ? 0 : 16, }, props.style]}>
            <View style={[Constants.styles.flexRow,
            { alignItems: 'center', justifyContent: 'space-between' },
            props.mobile ? Constants.styles.mobileMenuHeadingGroup : null,
            props.firstOption ? { marginTop: 0 } : null,
            props.mobile ? styles.mobile : null,
            !props.lastOptionBorder && props.lastOption ? { borderBottomWidth: 0 } : null]}>
                {props.link ?

                    <Link href={props.link} >{props.label}</Link>
                    :
                    <Text selectable={false} style={[Constants.styles.link, props.style]}>{props.label}</Text>
                }
                {shortcut && !props.mobile ? <Text selectable={false} style={[Constants.styles.menuShortcut, props.style]}>{shortcut}</Text> : null}
            </View>
        </Pressable>
    </>);

}


export default MenuOption


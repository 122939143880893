// src/Link.js

import React, { useEffect, useState, } from 'react'
import { View, StyleSheet, Pressable, Modal, Platform, Linking } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Icon } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField from './Input'
import Text from './Text';

function Link(props) {
    const styles = StyleSheet.create({
        disabledButton: {
            backgroundColor: Constants.colors.disabledOption,
            borderColor: Constants.colors.disabledOption.slice(0, -2), // remove opacity settings
            color: Constants.colors.disabledOption.slice(0, -2), // remove opacity settings
            cursor: 'not-allowed',
        }
    });

    if (props.disabled) {
        return (<>
            <Text style={[{ ...Constants.styles.link, cursor: props.disabled ? 'not-allowed' : 'pointer' },
            props.type == 'primary-outline' ? { ...Constants.styles.primaryOutlineButton, cursor: props.disabled ? 'not-allowed' : 'pointer' } : null,
            props.disabled ? styles.disabledButton : null,

            props.style

            ]}
            >{props.children}</Text>
        </>);
    }

    return (<>
        <Text style={[{ ...Constants.styles.link, cursor: props.disabled ? 'not-allowed' : 'pointer' },
        props.type == 'primary-outline' ? { ...Constants.styles.primaryOutlineButton, cursor: props.disabled ? 'not-allowed' : 'pointer' } : null,
        props.disabled ? styles.disabledButton : null,

        props.style

        ]}
            onPress={() => { if (props.disabled) return; return props.nullButton && props.onPress ? props.onPress() : Linking.openURL(props.href) }}>{props.children}</Text>
    </>);


}



export default Link


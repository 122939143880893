// src/ErrorCaught.js

import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View, Pressable, Platform, Button, TextInput, useWindowDimensions, Image, ActivityIndicator } from 'react-native';
import * as Constants from '../theme';


export default function ErrorCaught() {
    let [showSpin, setShowSpin] = useState(false);

    if (Platform.OS === 'web') document.body.classList.add('noScroll');
    return (
        <View style={{ overflow: 'hidden' }}>

            <View style={{ minHeight: '100vh', padding: Constants.leftOffset, overflow: 'hidden', width: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>

                <Image source={require('../assets/fullsize-bird.png')} style={{
                    height: 512 * .65,
                    width: 512 * .65,
                    position: 'absolute',
                    opacity: 0.2,
                    top: Constants.headerHeight * 4,
                }} />
                <Text style={{ fontSize: Constants.fontSizes.planInfoPrimaryPrice, maxWidth: 1000, fontWeight: '700' }}>A catastrophic error has occurred.</Text>
                <Text style={{ fontSize: Constants.fontSizes.planInfoPrimaryPrice * .7, maxWidth: 1000, fontWeight: '500' }}>We're sorry for the inconvenience.  <Text style={{ fontWeight: '800' }}>Your case data is safe.</Text>  If you see this message more than once, please reach out to support.</Text>
                <Text> </Text>
                <Text style={{ fontSize: Constants.fontSizes.planInfoPrimaryPrice * .7, maxWidth: 1000, fontWeight: '400', fontStyle: 'italic' }}>If you reach out to support, give as much information as possible as to what you were doing specifically when this happened.</Text>
                <Text style={{ fontSize: Constants.fontSizes.planInfoPrimaryPrice * .7, maxWidth: 1000, fontWeight: '400', fontStyle: 'italic' }}></Text>
                <Pressable onPress={() => { setShowSpin(true); window.location.reload(); }}
                    style={[Constants.styles.actionButton,
                    showSpin ? Constants.styles.disabledButton : Constants.styles.primaryButton,
                    { marginTop: Constants.leftOffset, cursor: 'pointer' }]}>
                    {showSpin ?
                        <ActivityIndicator style={{ alignItems: 'center' }} animating={showSpin} size="small" color={Constants.colors.primaryColorBG} />
                        : <Text style={[Constants.styles.primaryButton, { fontSize: Constants.fontSizes.planInfoPrimaryPrice * .7, maxWidth: 1000, fontWeight: '400', fontStyle: 'italic' }]}>Click here to try again.</Text>
                    }
                </Pressable>
            </View>
        </View>
    )
}
// src/Link.js

import React, { useEffect, useState, } from 'react'
import { Text, View, StyleSheet, Pressable, Modal, Platform, Linking } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Icon, Tooltip } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField from './Input'
import * as Data from './libs/data';

const ResultBadges = (args) => {
    // @TODO this should get the badges automatically

    // need to add some backwards compatibility for the old way of doing things
    let type = '';
    if (args.isOldCase) {

        switch (args.rank + '') {
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
                type = 'Immediate';
                break;
            case '7':
            case '8':
                type = 'Graded';
                break;
            case '9':
            case '10':
            case '11':
            case '12':
            case '13':
                type = 'ROP';
                break;
            case '15':
                type = 'Review Required';
                break;
            case Constants.excludedRank:
                type = 'Ineligible'
                break;
        }

    } else {

        /* ranks ... taken from ZYSYS::ZyINS::Constants */
        switch (args.rank + '') {
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
                type = 'Immediate';
                break;
            case '8':
            case '9':
                type = 'Graded';
                break;
            case '10':
            case '11':
            case '12':
            case '13':
            case '14':
                type = 'ROP';
                break;
            case '1':
                type = 'Review Required';
                break;
            case Constants.excludedRank:
                type = 'Ineligible'
                break;
        }
    }

    let isCompetitor = false;
    if (args.prevProductStr && args.prevProductStr.current) {
        // likely loaded from compute_case.php

        var regex = new RegExp("(" + args.prevProductStr.current.replace(/\s/g, '.').replace(/\$/g, ".[a-z\\-\\s\\*0-9]+$").toLowerCase() + ")");
        if (!args.data.identifier.match(regex)) {
            isCompetitor = true; // should never happen
        } else {
            isCompetitor = false;
        }
    } else {
        isCompetitor = Data.isCompetitorProduct(args);
    }

    let isDiscontinued = args.isDiscontinued ? args.isDiscontinued : false;

    /* note, due to a limitation of Tooltip and the current custom Text component, we had to revert to the native one */
    return (
        <View style={[Constants.styles.flexRow, styles.highestIndex, args.center ? { justifyContent: 'center', alignItems: 'center' } : null]} key={`badges-and-indicators-${args.data.identifier.replace(/\s/g, '-')}`}>
            <Tooltip isDisabled={args.hideTooltips} bg="darkText" _text={{
                color: "#fafaf9"
            }} shadow={2} style={[Constants.styles.defaultText, styles.tooltipStyle]}
                label={Constants.planRankClassTooltipDescription[type.toLowerCase()]} hasArrow={true} openDelay={100}>
                <Text style={[Constants.styles.badge, styles.badgeSpacing, args.center ? { marginRight: styles.badgeSpacing.marginLeft / 2, marginLeft: styles.badgeSpacing.marginLeft / 2 } : null]}>{type}</Text>
            </Tooltip>

            {isCompetitor ?
                <Tooltip isDisabled={args.hideTooltips} bg="darkText" _text={{
                    color: "#fafaf9"
                }} shadow={2} style={[Constants.styles.defaultText, styles.tooltipStyle]}
                    label="This is NOT a product you've checked in your products menu. You're seeing this result because you have FEX Competitive Analysis mode enabled in your product settings (Settings > Producer Settings > Set Available Products)"
                    hasArrow={true} openDelay={100}>
                    <Text style={[Constants.styles.badge, styles.badgeSpacing]}>Competitor</Text>
                </Tooltip>
                : null}

            {isDiscontinued ?
                <Tooltip isDisabled={args.hideTooltips} bg="darkText" _text={{
                    color: "#fafaf9"
                }} shadow={2} style={[Constants.styles.defaultText, styles.tooltipStyle]}
                    label="This product has been discontinued and is here for legacy reasons."
                    hasArrow={true} openDelay={100}>
                    <Text style={[Constants.styles.badge, styles.badgeSpacing]}>Discontinued</Text>
                </Tooltip>
                : null}
        </View >
    )
}



export default ResultBadges

const styles = new StyleSheet.create({
    badgeSpacing: {
        marginRight: 16.68,
    },
    highestIndex: {
        zIndex: 999999999999,
    },
    tooltipStyle:
        { paddingTop: 16, paddingLeft: 12, paddingRight: 12, paddingBottom: 16, maxWidth: 205, zIndex: 999999999999 }

});
// src/Row.js

import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import * as Constants from '../theme'
import { Tooltip } from 'native-base'
import Text from './Text';

function Row(props) {
    let internalPane = props.internalPane ? props.internalPane : 0;
    const styles = new StyleSheet.create({
        row: {
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'baseline',
            justifyContent: 'flex-between', // if you want to fill rows left to right
            marginBottom: props.bottomSpacing ? props.bottomSpacing : Constants.rowMarginBottom,
            alignContent: 'center',
            alignItems: 'center',
            zIndex: props.containsAutocomplete ?
                5 + ((props.activeAutocomplete == props.activeAutocompleteID) ? 1 : 0)
                : -5,
        },
        leftPart: {
            width: 157,
        },
        label: {
            alignContent: 'center',
            fontWeight: '600',
            fontSize: Constants.fontSizes.input,
            maxWidth: 157,
            width: '100%',
            marginRight: Constants.leftOffset,
            paddingBottom: 5,
            paddingTop: 5,
            color: Constants.colors.textColor,
        },
        content: {

            justifyContent: 'space-between',
            minHeight: 42,
            maxWidth: 458,
            minWidth: props.inModal ? 0 : 320,
            alignSelf: 'flex-start',
            paddingRight: Constants.leftOffset,
        }
    });

    return (
        <View style={[styles.row, props.style]}>
            <View style={[styles.leftPart, props.leftPart]}>
                <Text style={[styles.label, props.labelStyle]}>
                    {props.label}
                    {props.labelIconTooltip ?
                        <Tooltip bg="darkText" _text={{
                            color: "#fafaf9"
                        }} shadow={2} style={{ paddingTop: 16, paddingLeft: 12, paddingRight: 12, paddingBottom: 16, maxWidth: 205 }}
                            label={props.labelIconTooltip} hasArrow={true} openDelay={500}>{props.labelIcon}</Tooltip>
                        : props.labelIcon}
                </Text>
                {props.showOptional ? <Text style={{ color: Constants.colors.textColor }}>(optional)</Text> : null}
            </View>
            <View style={[{ width: Constants.leftOffset - (10 * internalPane) }, { maxWidth: '100%' }]}></View>
            <View style={[{ maxWidth: '100%' }, styles.rightWrap]}>
                <View style={[styles.content, props.rightStyle]}>
                    {props.children}
                </View>
            </View>
        </View >);
}

export default Row

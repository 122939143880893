// src/KeyboardShortcut.js

import React, { useEffect, useRef, useState, } from 'react'
import { View, StyleSheet, Pressable, Modal, Platform, Image, Linking } from 'react-native'
import * as Constants from '../theme'
import { AntDesign, FontAwesome } from '@expo/vector-icons';
import { Box, Icon } from 'native-base';
import Pane from './Pane';
import Row from './Row';
import TextInputField from './Input'
import Link from './Link';
import * as Data from './libs/data'
import Text from './Text';


export default function KeyboardShortcut(props) {

    const callback = useRef(props.callback);
    useEffect(() => {
        callback.current = props.callback;
    })

    let shortcuts = [].concat([props.moddedShortcut]).concat([props.shortcut]).concat([props.shiftShortcut]);

    const handler = (e) => {
        if (props.moddedShortcut) {
            if ((e.ctrlKey || e.altKey || e.metaKey) && shortcuts.includes(e.key)) {
                if (props.setOpenMenu) props.setOpenMenu('');
                callback.current(e);
            }
        }

        if (props.shiftShortcut) {
            if ((e.shiftKey) && shortcuts.includes(e.key)) {
                if (props.setOpenMenu) props.setOpenMenu('');
                callback.current(e);
            }
        }

        if (props.shortcut) {
            if (shortcuts.includes(e.key)) {
                if (props.setOpenMenu) props.setOpenMenu('');
                callback.current(e);
            }
        }
    };

    if (Platform.OS === 'web') {

        let type = props.type ? props.type : 'keyup';
        useEffect(() => {
            shortcuts = [].concat([props.moddedShortcut]).concat([props.shortcut]).concat([props.shiftShortcut]);
            document.addEventListener(type, handler);
            return () => document.removeEventListener(type, handler);
        }, [props.moddedShortcut, props.shortcut])

    }


};
